import { stat } from 'fs';
import { User } from '../../interfaces/user';
import { mapBundleItemServerToClient } from '../bundles/mapper';
import { UserBundle } from '../../interfaces/bundle';

export const mapUserFromServerToClient = (user: any): User => {
  return mapUserShortFromServerToClient(user);
};
export const mapUserShortFromServerToClient = (user: any): User => ({
  id: user.id,
  email: user.email,
  mobile: user.mobile,
  name: user.name,
  status: user.status === 1 ? 'Active' : 'Inactive',
  username: user.username,
  avatar: user.avatar,
  registeredAt: user.registered_at,
  pointsEarned: user.points_earned || 0,
  pointsSpent: user.points_spent || 0,
  pointsBalance: (user.points_earned || 0) - (user.points_spent || 0),
  ...user,
});

export const mapUsersFromServerToClient = (users: any[]): any[] =>
  users.map(mapUserFromServerToClient);

export const mapUsersShortFromServerToClient = (users: any[]): any[] =>
  users.map(mapUserShortFromServerToClient);

const mapBundleItem = (item: any): UserBundle => {
  const data = mapBundleItemServerToClient(item);
  const stats = {
    readCount: item.stats?.read_count,
    strength: item.stats?.strength,
    weakness: item.stats?.weakness,
    dueDays: item.stats?.due_days,
    dueReading: item.stats?.due_reading,
    dueRevision: item.stats?.due_revision,
  };
  const userData = { ...data, stats: { ...data.stats, ...stats } };
  // eslint-disable-next-line no-console
  return userData;
};
export const mapUserBundles = (bundles: any[]): UserBundle[] => {
  return bundles.map(mapBundleItem);
};
