import * as endpoints from '../../configs/endpoints';
import { MetaInterface } from '../../interfaces/api';
import { Campaign } from '../../interfaces/campaign';
import { axioService, GET, POST } from '../../services/axioService';
import { getProviderId } from '../../services/utility';
import { mapItemFromServerToClient } from '../points/mapper';
import { mapMeta, ResponseInterface, SingleResponseInterface } from '../util';
import { mapItemListServerToClient } from './mapper';

/**
 * Fetch all users for the current provider with pagination
 * @param request - The request object
 * @returns {Promise<ResponseInterface<Campaign>>}
 */
export const getCampaigns = async (
  request?: any
): Promise<ResponseInterface<Campaign>> => {
  const response = await axioService(
    GET,
    endpoints.SERVER_CAMPAIGNS_ENDPOINT,
    request,
    true
  );
  return {
    meta: mapMeta(response.data.meta),
    data: mapItemListServerToClient(response.data.data),
  };
};

/**
 * create a new item
 * @param request the request body
 * @returns {Promise<SingleResponseInterface<Campaign>>}
 */
export const createCampaign = async (
  request: any
): Promise<SingleResponseInterface<Campaign>> => {
  const response = await axioService(
    POST,
    endpoints.SERVER_CAMPAIGN_CREATE_ENDPOINT,
    request,
    true
  );
  return {
    data: mapItemFromServerToClient(response.data.data),
  };
};

/**
 * update the item
 * @param request the request body
 * @returns {Promise<Campaign>} the updated item
 */
export const updateCampaign = async (
  request: any
): Promise<SingleResponseInterface<Campaign>> => {
  const response = await axioService(
    POST,
    endpoints.SERVER_CAMPAIGN_UPDATE_ENDPOINT.replace(':id', request.id),
    request,
    true
  );
  return {
    data: mapItemFromServerToClient(response.data),
  };
};

/**
 * Delete the item.
 * @param request the request body
 * @returns {Promise<Campaign>} the updated item
 */
export const deleteCampaign = async (request: any): Promise<{ data: any }> => {
  const response = await axioService(
    POST,
    endpoints.SERVER_CAMPAIGN_DELETE_ENDPOINT.replace(':id', request.id),
    request,
    true
  );
  return {
    data: response.data,
  };
};

/**
 * Delete the item.
 * @param request the request body
 * @returns {Promise<Campaign>} the updated item
 */
export const sendCampaign = async (request: any): Promise<{ data: any }> => {
  const response = await axioService(
    POST,
    endpoints.SERVER_CAMPAIGN_SEND_ENDPOINT.replace(':id', request.id),
    request,
    true
  );
  return {
    data: response.data,
  };
};
