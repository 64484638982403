/* eslint-disable no-console */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Layout from '../components/layout/Layout';
import LessonList from '../components/lessonList/LessonList';
const Lessons = () => {
  return (
    <Layout>
      <LessonList />
    </Layout>
  );
};

export default Lessons;
