/* eslint-disable no-console */
import * as endpoints from '../../configs/endpoints';
import { axioService, GET, POST } from '../../services/axioService';
import { mapChallengesServerToClient } from './mapper';

/**
 * Fetch all appointments for the current provider with pagination
 * @param request
 * @returns {Promise<*>}
 */
export const getChallenges = async (request: any): Promise<any> => {
  const response = await axioService(
    GET,
    endpoints.SERVER_CHALLENGES_ENDPOINT,
    request,
    true
  );

  return {
    data: mapChallengesServerToClient(response.data.data),
    meta: response.data.meta,
  };
};

export const resolveChallenge = async (
  request: any
): Promise<{
  data: any;
}> => {
  const response = await axioService(
    POST,
    endpoints.SERVER_CHALLENGE_MARK_AS_RESOLVED_ENDPOINT.replace(
      ':id',
      request.id
    ),
    { status: request.status, remarks: request.remarks || '' },
    true
  );
  return {
    data: response,
  };
};

export const rejectChallenge = async (
  request: any
): Promise<{
  data: any;
}> => {
  const response = await axioService(
    POST,
    endpoints.SERVER_CHALLENGE_MARK_AS_REJECTED_ENDPOINT.replace(
      ':id',
      request.id
    ),
    request,
    true
  );
  return {
    data: response,
  };
};
