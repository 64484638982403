/* eslint-disable no-console */
import { Lesson } from '../../interfaces/lesson';
import { getYMD } from '../../services/utility';

export const mapLessonItemServerToClient = (lesson: any): Lesson => {
  return {
    ...lesson,
    bundleId: lesson.bundle_id,
    stats: {
      flashcardCount: lesson.stats?.flashcard_count,
      tagCount: lesson.stats?.tag_count,
      userCount: lesson.stats?.user_count,
    },
  };
};

export const mapLessonsServerToClient = (lessonList: any[]): Lesson[] => {
  return lessonList.map(mapLessonItemServerToClient);
};
