/* eslint-disable no-console */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import ChallengeList from '../components/challengeList/ChallengeList';
import Layout from '../components/layout/Layout';

const Challenges = () => {
  return (
    <Layout>
      <ChallengeList />
    </Layout>
  );
};

export default Challenges;
