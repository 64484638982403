/* eslint-disable no-console */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { PageContainer, ProLayout } from '@ant-design/pro-components';
import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../containers/header/Header';
import * as endpoints from '../../configs/endpoints';
import { useLocation } from 'react-router-dom';
import Logo from './Logo';
import {
  FormOutlined,
  PieChartOutlined,
  QuestionOutlined,
  SnippetsOutlined,
} from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { LayoutContext } from './LayoutContext';
// import { CreateBundle } from '../createBundle/CreateBundle';
import routes from '../../routes';
interface Props {
  children: React.ReactNode;
  headerContent?: React.ReactNode;
}

const Layout = (props: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [pageMenus, setPageMenu] = useState([]);
  const setExtra = (val: React.SetStateAction<never[]>) => {
    setPageMenu(val);
  };

  const [shortLogo, setShortLog] = useState(false);

  return (
    <LayoutContext.Provider value={{ setPageMenus: setExtra }}>
      <div
        style={{
          height: '100vh',
        }}
      >
        <ProLayout
          navTheme="light"
          // headerTheme="dark"
          headerRender={() => <Header />}
          // headerHeight={50}
          title=""
          logo={<Logo logoShort={shortLogo} />}
          siderWidth={270}
          breadcrumbRender={(routers: any) => {
            console.log('routes-->', routers);
            return [
              {
                path: '/',
                breadcrumbName: 'Home',
              },
              ...routers,
            ];
          }}
          defaultCollapsed={false}
          onCollapse={(val) => setShortLog(val)}
          menuItemRender={(item: any, dom: any) => (
            <a
              onClick={(e) => {
                e.preventDefault();
                navigate(item.path);
              }}
              href={item.path}
            >
              {dom}
            </a>
          )}
          route={{ routes }}
        >
          <PageContainer
            extra={pageMenus.map((val: any, index) => {
              const MenuComponent = val.component || null;
              return <MenuComponent key={index} />;
            })}
          >
            {props.children}
          </PageContainer>
        </ProLayout>
      </div>
    </LayoutContext.Provider>
  );
};

export default Layout;
