import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import CreateBundleForm from '../bundleForm/BundleForm';

const CreateBundle = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  return (
    <>
      <Button
        onClick={() => {
          setIsModalVisible(!isModalVisible);
        }}
      >
        <PlusOutlined /> Bundle
      </Button>

      <Modal
        title={'Create Bundle'}
        open={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        width="60%"
        footer={false}
      >
        <CreateBundleForm
          onUpdate={setIsModalVisible}
          submitBtnLabel={'Create'}
        />
      </Modal>
    </>
  );
};

export default CreateBundle;
