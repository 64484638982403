import React from 'react';
import { useNavigate } from 'react-router-dom';
import LogoImgShort from './LogoImgShort';
import LogoFullImg from './LogoImgFull';

interface Props {
  logoShort?: boolean;
}
const Logo = (props: Props) => {
  const navigate = useNavigate();
  return (
    <>
      <a
        href="/"
        onClick={(e) => {
          e.preventDefault();
          navigate('/dashboard');
        }}
      >
        {props.logoShort ? <LogoImgShort /> : <LogoFullImg />}
      </a>
    </>
  );
};

export default Logo;
