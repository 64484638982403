/* eslint-disable no-console */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Layout from '../components/layout/Layout';
import FlashcardList from '../components/flashcardList/FlashcardList';
const Flashcards = () => {
  return (
    <Layout>
      <FlashcardList />
    </Layout>
  );
};

export default Flashcards;
