import { Avatar, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { getBundles } from '../../api/bundles/request';
import DropdownCard from '../dropdownCard/DropdownCard';
import { debounce } from 'lodash';

interface BundleSelectionDropdownPros {
  onChange?: any;
  onClear?: any;
  selectedId?: any;
  isDisabled?: boolean;
  mode?: 'multiple' | 'tags';
  placeholder?: string;
}

const BundleSelectionDropdown = (props: BundleSelectionDropdownPros) => {
  const [bundles, setBundles] = useState([]);

  useEffect(() => {
    fetchBundles({ ids: props.selectedId });
  }, [props.selectedId]);

  const fetchBundles = debounce(async (params) => {
    const response = await getBundles(params);
    if (response.data && response.data.length > 0) {
      setBundles(response.data);
    }
  }, 500);

  return (
    <div>
      <Select
        style={{ width: '100%' }}
        allowClear
        showSearch
        mode={props.mode}
        onSearch={fetchBundles}
        onClear={props.onClear}
        onChange={props.onChange}
        placeholder={props.placeholder || 'Search Bundle'}
        notFoundContent={null}
        defaultValue={props.selectedId}
        filterOption={(input, option) =>
          (option?.children as any)?.props.item.title
            .toLowerCase()
            .includes(input.toLowerCase())
        }
        disabled={props.isDisabled === true}
      >
        {bundles.map((itr: any, index: number) => (
          <Select.Option title={itr.title || ''} key={itr.id} value={itr.id}>
            <DropdownCard
              item={{
                photo: (
                  <Avatar
                    size={67}
                    shape="square"
                    src={itr.thumbnail}
                    // icon={<UserOutlined />}
                  />
                ),
                title: itr.title,
                description: `${itr.description}`,
              }}
            />
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default BundleSelectionDropdown;
