import { ProColumns, ProTable } from '@ant-design/pro-components';
import React from 'react';
import moment from 'moment';
import BundleSelectionDropdown from '../input/BundleSelectionDropdown';
import { Tooltip, Typography } from 'antd';
import styles from './UserList.module.scss';
import { useSearchParams } from 'react-router-dom';
import { Quiz } from '../../interfaces/quiz';
import { getStudyLog } from '../../api/studies/request';
import { StudyLog as StudyLogInterface } from '../../interfaces/studyLog';
import UserSelectionDropdown from '../input/UserSelectionDropdown';
import { BaseType } from 'antd/es/typography/Base';
interface Props {
  userId?: number;
  search?: boolean;
}
const StudyLog = (props: Props) => {
  let slNo = 1;
  const [searchParams, setSearchParams] = useSearchParams();

  const columns: ProColumns<StudyLogInterface>[] = [
    {
      title: 'SL#',
      hideInSearch: true,
      render: (_text, _record, index) => `${slNo + index}`.padStart(2, '0'),
    },
    {
      title: 'Users',
      dataIndex: 'user_id',
      hideInTable: true,
      hideInSearch: props.userId !== undefined,
      renderFormItem(schema, config, form, action) {
        return (
          <UserSelectionDropdown
            mode="multiple"
            onChange={(value: any) => {
              form.setFieldValue('user_id', value);
            }}
            onClear={() => {
              form.setFieldValue('user_id', '');
            }}
          />
        );
      },
      fieldProps: {
        placeholder: 'Users',
      },
    },
    {
      title: 'Bundles',
      dataIndex: 'bundle_id',
      renderFormItem(schema, config, form, action) {
        return (
          <BundleSelectionDropdown
            mode="multiple"
            onChange={(value: any) => {
              form.setFieldValue('bundle_id', value);
            }}
            onClear={() => {
              form.setFieldValue('bundle_id', '');
            }}
          />
        );
      },
      fieldProps: {
        placeholder: 'Bundles',
      },
      render: (text, record) => {
        return <span> {record.flashcard?.bundle_title}</span>;
      },
    },
    {
      title: 'Flashcard',
      hideInSearch: true,
      render: (text, record) => {
        return (
          <Tooltip title={record.flashcard?.bundle_title}>
            {record.flashcard?.title}
          </Tooltip>
        );
      },
      width: '350px',
    },
    {
      title: 'Study Type',
      valueType: 'select',
      dataIndex: 'type',
      valueEnum: {
        reading: { text: 'Read' },
        revision: { text: 'Revision' },
        quiz: { text: 'Quiz' },
      },
      render: (text, record) => {
        const typography: any = ['success', 'warning', 'secondary'][
          record.isRight
        ];
        return <Typography.Text type={typography}>{text}</Typography.Text>;
      },
    },
    {
      title: 'Time',
      render: (text, record) => {
        const date = moment(record.time).format('DD-MMM-yyyy');
        const dateTime = moment(record.time).format('DD-MMM-yyyy, hh:mm A');
        return <Tooltip title={dateTime}>{date}</Tooltip>;
      },
      valueType: 'dateRange',
      search: {
        transform: (value) => {
          return {
            start_date: value[0],
            end_date: value[1],
          };
        },
      },
    },
  ];

  return (
    <>
      <ProTable
        columns={columns}
        rowKey={'id'}
        pagination={{
          pageSize: 15,
        }}
        request={async (params, sorter, filter) => {
          params = {
            page: params.current,
            per_page: params.pageSize,
            ...params,
          };

          if (props.userId) {
            params['user_id'] = [props.userId];
          }

          const response = await getStudyLog(params);
          slNo = ((params.current || 0) - 1) * (params.pageSize || 0) + 1;

          return {
            data: response.data,
            // Please return true for success.
            // otherwise the table will stop parsing the data, even if there is data
            success: true,
            // not passed will use the length of the data, if it is paged you must pass
            total: response.meta?.total,
          };
        }}
      />
    </>
  );
};

export default StudyLog;
