/* eslint-disable no-console */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import BundleList from '../components/bundleList/BundleList';
import Layout from '../components/layout/Layout';

const Bundles = () => {
  return (
    <Layout>
      <BundleList />
    </Layout>
  );
};

export default Bundles;
