/* eslint-disable no-console */

import { SERVER_ADMIN_DASHBOARD_ENDPOINT } from '../../configs/endpoints';
import { axioService, GET, POST } from '../../services/axioService';

/**
 * Get all patients
 * @param page the number of page
 * @returns {Promise<CreatePatientResponse[]>} a promise of array of patients with pagination
 */
export const dashboard = async (request: any): Promise<any> => {
  const response = await axioService(
    GET,
    SERVER_ADMIN_DASHBOARD_ENDPOINT,
    request,
    true
  );
  return response.data;
  // return {
  //   ...response.data,
  //   bundle: [...response.data.bundle, { total: 3, timeline: '2022-07-25' }],
  // };
};

// export const updateBundle = async (
//   request: any
// ): Promise<{
//   data: any;
// }> => {
//   const response = await axioService(
//     POST,
//     endpoints.SERVER_BUNDLE_UPDATE_ENDPOINT.replace(':id', request.id),
//     request,
//     true
//   );
//   return {
//     data: response,
//   };
// };
