export enum AppointmentStatus {
  Pending = '0',
  Confirmed = '1',
  Canceled = '2',
  Present = '5',
  Visiting = '6',
  Visited = '3',
  Missed = '4',
}

export enum ChallengeStatus {
  Pending = 0,
  Resolved = 1,
  Rejected = 2,
  Canceled = 3,
}
