import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import LessonForm from './LessonForm';

const CreateLesson = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  return (
    <>
      <Button
        onClick={() => {
          setIsModalVisible(!isModalVisible);
        }}
      >
        <PlusOutlined /> Lesson
      </Button>

      <Modal
        title={'Create Lesson'}
        open={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        width="60%"
        footer={false}
      >
        <LessonForm onUpdate={setIsModalVisible} submitBtnLabel={'Create'} />
      </Modal>
    </>
  );
};

export default CreateLesson;
