import { Quiz, QuizResponse } from '../../interfaces/quiz';

export const mapFromServerToClient = (item: any): Quiz => {
  return mapShortFromServerToClient(item);
};
const mapResponse = (item: any): QuizResponse => ({
  questionId: item.question_id,
  questionTitle: item.question_title,
  rightAnswer: item.right_answer,
  givenAnswer: item.given_answer,
  result: item.result,
});

export const mapShortFromServerToClient = (item: any): Quiz => ({
  id: item.id,
  quizNo: item.quiz_no,
  category: item.category,
  totalMarks: item.total_marks,
  time: item.quizzed_at,
  score: item.score,
  response: (item.response || []).map(mapResponse),
});

export const mapsFromServerToClient = (items: any[]): any[] =>
  items.map(mapFromServerToClient);

export const mapsShortFromServerToClient = (items: any[]): any[] =>
  items.map(mapShortFromServerToClient);
