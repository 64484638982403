/* eslint-disable no-console */
import { Button, Col, Form, Input, InputNumber, Row, Select } from 'antd';
import clsx from 'clsx';
import React, { useState } from 'react';
import styles from './Styles.module.scss';
import { addPoints } from '../../api/points/request';

interface Props {
  onAdd: any;
  userId: number;
  title?: string;
}

const PointsForm = (props: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (request: any) => {
    console.log('Form data', request);
    const payload = {
      ...request,
      user_id: props.userId,
      amount: request.amount,
      trn_type: request.trn_type || 'add',
      particulars: request.particulars || 'Rewards by Studilog ',
    };
    const resp = await addPoints(payload);
    props.onAdd(resp.data);
  };

  const onReset = () => {
    form.resetFields();
  };
  return (
    <div className={clsx(styles.FormWrapper, 'bg-white form__content')}>
      {!props.title ? null : <h2>{props.title}</h2>}
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        form={form}
        name="control-hooks"
        onFinish={onFinish}
      >
        <Row gutter={15}>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              name="amount"
              label="Points"
              rules={[{ required: true }]}
            >
              <InputNumber width={'100%'} min={0} />
            </Form.Item>
          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              name="trn_type"
              label="Trn. Type"
              rules={[{ required: false }]}
            >
              <Select style={{ width: '100%' }} defaultValue={'add'}>
                <Select.Option key="add" value="add">
                  Add Points
                </Select.Option>
                <Select.Option key="sub" value="sub">
                  Subtract Points
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              name="particulars"
              label="Particulars"
              rules={[{ required: false }]}
            >
              <Input.TextArea rows={2} placeholder="Rewards by Studilog" />
            </Form.Item>
          </Col>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Form.Item>
              <Button loading={loading} type="primary" htmlType="submit">
                {'Submit'}
              </Button>
              <Button
                style={{ marginLeft: '20px' }}
                htmlType="button"
                onClick={onReset}
              >
                Reset
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default PointsForm;
