/* eslint-disable no-console */
import { Col, Row, Space, Tooltip, Typography } from 'antd';
import React, { useState } from 'react';
import styles from './ShortCard.module.scss';
// @ts-ignore
import CopyToClipboard from 'react-copy-to-clipboard';
import { debounce } from 'lodash';

interface Props {
  photo: string;
  title: string;
  description?: string;
  url?: string;
}

export default function ShortCard(props: Props) {
  const [copied, setCopied] = useState(false);

  const onMouseLeave = debounce(() => setCopied(false), 200);
  const Copy = ({ text, value }: any) => {
    return (
      <Tooltip
        title={
          copied === true
            ? `${text.replace('Copy', '')} Copied!`
            : 'Click to Copy'
        }
      >
        <CopyToClipboard text={value} onCopy={() => setCopied(true)}>
          <Typography.Link
            type="secondary"
            onMouseLeave={onMouseLeave}
            onPointerLeave={onMouseLeave}
          >
            {' '}
            {text}{' '}
          </Typography.Link>
        </CopyToClipboard>
      </Tooltip>
    );
  };
  return (
    <div className={styles.bundleTitle}>
      <Row gutter={15}>
        {props.photo && (
          <Col>
            <img src={props.photo} alt={props.title} width="60" />{' '}
          </Col>
        )}
        <Col>
          <div>
            <h4 style={{ margin: 0 }}>{props.title}</h4>
            <h5>{props.description}</h5>
          </div>
        </Col>
      </Row>
      <div className={styles.actions}>
        {props.title && <Copy text={'Copy Title'} value={props.title} />}

        {props.url && <Typography.Text disabled> | </Typography.Text>}
        {props.url && <Copy text={'Copy URL'} value={props.url} />}

        {props.photo && <Typography.Text disabled> | </Typography.Text>}
        {props.photo && <Copy text={'Copy Img'} value={props.photo} />}
      </div>
    </div>
  );
}
