import React, { useRef } from 'react';
import { getCampaigns } from '../../api/campaigns/request';
import { ActionType, ProColumns, ProTable } from '@ant-design/pro-components';
import { Campaign } from '../../interfaces/campaign';
import { Button, Typography } from 'antd';
import AddCampaign from './AddCampaign';
import EditCampaign from './EditCampaign';
import { debounce } from 'lodash';
import RunCampaign from './RunCampaign';

const CampaignList = () => {
  let slNo = 1;

  const columns: ProColumns<Campaign>[] = [
    {
      title: 'SL#',
      dataIndex: 'sl',
      hideInSearch: true,
      render: (text, record, index) => slNo + index,
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Title',
      dataIndex: 'title',
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text, record) => {
        switch (record.status) {
          case 'draft':
            return <Typography.Text disabled>Draft</Typography.Text>;
          case 'active':
            return <Typography.Text type="warning">Active</Typography.Text>;
          case 'processed':
            return <Typography.Text type="success">Processed</Typography.Text>;
          default:
            return <Typography.Text>Processed</Typography.Text>;
        }
      },
    },
    {
      title: 'Message',
      dataIndex: 'message',
    },

    {
      title: 'Action',
      dataIndex: 'action',
      width: '160px',
      render: (_text, record, index) => {
        return (
          <>
            <EditCampaign campaign={record} onAdd={onAddOrUpdate} />
            {record.type === 'notification' && record.status === 'active' && (
              <RunCampaign
                campaign={record}
                onComplete={() => actionRef.current?.reload()}
              />
            )}
          </>
        );
      },
    },
  ];

  const actionRef = useRef<ActionType>();
  const onAddOrUpdate = debounce((record: Campaign) => {
    actionRef.current?.reload();
  }, 500);

  return (
    <>
      <ProTable
        columns={columns}
        rowKey={'id'}
        actionRef={actionRef}
        request={async (params, sorter, filter) => {
          params = {
            page: params.current,
            per_page: params.pageSize,
            ...params,
          };
          // eslint-disable-next-line no-console
          const response = await getCampaigns(params);
          //   setSearchParams(params);

          slNo = ((params.current || 0) - 1) * (params.pageSize || 0) + 1;

          return {
            data: response.data,
            // Please return true for success.
            // otherwise the table will stop parsing the data, even if there is data
            success: true,
            // not passed will use the length of the data, if it is paged you must pass
            total: response.meta?.total,
          };
        }}
        toolBarRender={() => [
          <AddCampaign key={'add-new'} onAdd={onAddOrUpdate} />,
        ]}
      />
    </>
  );
};

export default CampaignList;
