import * as endpoints from '../../configs/endpoints';
import { MetaInterface } from '../../interfaces/api';
import { axioService, GET, POST } from '../../services/axioService';
import { getProviderId } from '../../services/utility';
import { mapMeta } from '../util';
import { mapItemFromServerToClient, mapsFromServerToClient } from './mapper';

interface ResponseInterface {
  data: Array<any> | any | null | any;
  meta?: MetaInterface | null;
}

/**
 * Fetch all users for the current provider with pagination
 * @param request - The request object
 * @returns {Promise<ResponseInterface>}
 */
export const getPoints = async (request?: any): Promise<ResponseInterface> => {
  const response = await axioService(
    GET,
    endpoints.SERVER_POINTS_ENDPOINT,
    request,
    true
  );
  return {
    meta: mapMeta(response.data.meta),
    data: mapsFromServerToClient(response.data.data),
  };
};

/**
 * create a new doctor
 * @param request the request body
 * @returns {Promise<UserDetailsInterface>} the updated doctor
 */
export const addPoints = async (
  request: any
): Promise<{
  data: any;
}> => {
  const response = await axioService(
    POST,
    endpoints.SERVER_POINTS_ENDPOINT,
    request,
    true
  );
  return {
    data: mapItemFromServerToClient(response.data),
  };
};
