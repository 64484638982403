/* eslint-disable no-console */
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
} from 'antd';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { createLesson, updateLesson } from '../../api/lessons/request';
import { getTopics } from '../../api/topics/request';
import { Lesson } from '../../interfaces/lesson';
import styles from './LessonForm.module.scss';
import BundleSelectionDropdown from '../input/BundleSelectionDropdown';
const { Option } = Select;

interface Props {
  lesson?: Lesson;
  submitBtnLabel?: string;
  onUpdate: any;
}

const LessonForm = (props: Props) => {
  const lesson = props.lesson;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [thumbnail, setThumbnail] = useState(lesson?.thumbnail);
  const [bundleId, setBundleId] = useState(lesson?.bundleId || '');
  const [topics, setTopics] = useState([]);

  const isEdit = !!props.lesson;

  useEffect(() => {
    form.setFieldsValue({
      title: '',
      price: '',
      edition: '',
      description: '',
      priority: '',
      topic_id: '',
      status: '',
      ...lesson,
    });
  }, [props.lesson]);

  useEffect(() => {
    fetchTopics();
  }, []);

  const fetchTopics = async () => {
    try {
      const response = await getTopics();
      setTopics(response);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  const onFinish = async (request: any) => {
    console.log('Form data', request);
    const lessonId = lesson?.id;
    const payload = {
      title: request.title,
      description: request.description,
      priority: request.priority,
      status: request.status,
      search_keywords: request.search_keywords,
      thumbnail,
      bundle_id: bundleId,
    };
    if (lessonId) {
      const resp = await updateLesson({ ...payload, id: lessonId });
      props.onUpdate({ ...lesson, ...payload });
    } else {
      const resp = await createLesson(payload);
      if (resp.data.status === 201) {
        message.success('lesson created successfully');
        props.onUpdate(false);
      }
    }
  };

  const onReset = () => {
    form.resetFields();
  };
  return (
    <div className={clsx(styles.FormWrapper, 'bg-white form__content')}>
      <Form
        initialValues={props.lesson}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        form={form}
        name="control-hooks"
        onFinish={onFinish}
      >
        <Row gutter={15}>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Form.Item name="title" label="Title" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              name="priority"
              label="Priority"
              rules={[{ required: true }]}
            >
              <InputNumber min={0} />
            </Form.Item>
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Form.Item
              name="bundle_id"
              label="Bundle"
              rules={[{ required: true }]}
            >
              <BundleSelectionDropdown
                onChange={(id: any) => {
                  setBundleId(id);
                }}
                selectedId={bundleId}
                isDisabled={
                  lesson?.bundleId && lesson?.stats.flashcardCount
                    ? true
                    : false
                }
              />
            </Form.Item>
          </Col>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              name="description"
              label="Description"
              rules={[{ required: false }]}
            >
              <Input.TextArea rows={3} />
            </Form.Item>
          </Col>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              name="search_keywords"
              label="Search Keywords"
              rules={[{ required: false }]}
              help="Separate keywords by semicolon (;)."
            >
              <Input.TextArea rows={2} />
            </Form.Item>
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Form.Item
              name="thumbnail"
              label="Thumbnail"
              rules={[{ required: false }]}
            >
              <Input.TextArea
                rows={4}
                onChange={(e) => {
                  setThumbnail(e.target.value);
                }}
              />
            </Form.Item>
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Form.Item name="preview" label="Preview">
              {thumbnail && (
                <img src={thumbnail} alt={lesson?.title} width="170px" />
              )}
            </Form.Item>
          </Col>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Form.Item>
              <Button loading={loading} type="primary" htmlType="submit">
                {props.submitBtnLabel || 'Update'}
              </Button>
              <Button
                style={{ marginLeft: '20px' }}
                htmlType="button"
                onClick={onReset}
              >
                Reset
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default LessonForm;
