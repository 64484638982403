import '@fortawesome/fontawesome-free/css/all.min.css';
import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Store } from 'redux';
import * as endpoints from '../configs/endpoints';
import ConnectedAccount from '../containers/account/Account';
import ConnectedAccountRecovery from '../containers/accountRecovery/AccountRecovery';
import ConnectedGuestRoute from '../containers/guestRoute/GuestRoute';
import Login from '../containers/login/Login';
import ConnectedPrivateRoute from '../containers/privateRoute/PrivateRoute';
import Register from '../containers/register/Register';
import ConnectedResetPassword from '../containers/resetPassword/ResetPassword';
import ConnectedSessionAuthorize from '../containers/sessionAuthorize/SessionAuthorize';
import ConnectedSessionDestroy from '../containers/sessionDestroy/SessionDestroy';
import ConnectedVerifyEmail from '../containers/verifyEmail/VerifyEmail';
import routes from '../routes';
import { axioService, GET } from '../services/axioService';
import { getSessionToken, setSessionUserInfo } from '../store/ducks/session';
import './App.scss';

interface AppProps {
  token: string;
  setSessionUserInfoActionCreator: typeof setSessionUserInfo;
}

const App: React.FC<AppProps> = (props: AppProps) => {
  const { token, setSessionUserInfoActionCreator } = props;
  /** fetches initial data based on login */
  React.useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axioService(
          GET,
          endpoints.SERVER_USER_PROFILE_ENDPOINT,
          {},
          true
        );
        setSessionUserInfoActionCreator(response.data.data);
      } catch (exception) {
        /** console error the exception */
        console.error(exception);
      }
    };
    if (token !== '') {
      fetchProfile();
    }
  }, [token]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route
            path={'/'}
            element={
              <ConnectedGuestRoute>
                <Login />
              </ConnectedGuestRoute>
            }
          />
          <Route
            path={endpoints.LOCAL_LOGIN_ENDPOINT}
            element={
              <ConnectedGuestRoute>
                <Login />
              </ConnectedGuestRoute>
            }
          />
          <Route
            path={endpoints.LOCAL_REGISTER_ENDPOINT}
            element={
              <ConnectedGuestRoute>
                <Register />
              </ConnectedGuestRoute>
            }
          />
          {routes.map((val) => {
            const RoutComponent = val.component;
            return (
              <Route
                key={val.name}
                path={val.path}
                element={
                  <ConnectedPrivateRoute>
                    {RoutComponent ? <RoutComponent /> : null}
                  </ConnectedPrivateRoute>
                }
              />
            );
          })}
        </Route>
        <Route
          path={endpoints.LOCAL_LOGOUT_ENDPOINT}
          element={
            <ConnectedPrivateRoute>
              <ConnectedSessionDestroy />
            </ConnectedPrivateRoute>
          }
        />
        <Route
          path={endpoints.LOCAL_AUTHORIZE_ENDPOINT}
          element={<ConnectedSessionAuthorize />}
        />
        <Route
          path={endpoints.LOCAL_FORGOT_PASSWORD_ENDPOINT}
          element={
            <ConnectedGuestRoute>
              <ConnectedAccountRecovery />
            </ConnectedGuestRoute>
          }
        />
        <Route
          path={endpoints.LOCAL_RESET_PASSWORD_ENDPOINT}
          element={
            <ConnectedGuestRoute>
              <ConnectedResetPassword />
            </ConnectedGuestRoute>
          }
        />
        <Route
          path={endpoints.LOCAL_PERSONAL_INFO_ENDPOINT}
          element={
            <ConnectedPrivateRoute>
              <ConnectedAccount />
            </ConnectedPrivateRoute>
          }
        />
        <Route
          path={endpoints.LOCAL_VERIFY_EMAIL_ENDPOINT}
          element={
            <ConnectedPrivateRoute>
              <ConnectedVerifyEmail />
            </ConnectedPrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  token: string;
}

/** Map props to state  */
const mapStateToProps = (state: Partial<Store>): DispatchedStateProps => {
  const result = {
    token: getSessionToken(state),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {
  setSessionUserInfoActionCreator: setSessionUserInfo,
};

/** connect App to the redux store */
const ConnectedApp = connect(mapStateToProps, mapDispatchToProps)(App);

/** the default export */
export default ConnectedApp;
