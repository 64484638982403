import React from 'react';
import Layout from '../components/layout/Layout';
import StudyLog from '../components/studies';

const StudyLogs = () => {
  return (
    <Layout>
      <StudyLog />
    </Layout>
  );
};

export default StudyLogs;
