import {
  BookOutlined,
  FormOutlined,
  NotificationOutlined,
  PieChartOutlined,
  QuestionOutlined,
  SnippetsOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import * as endpoints from '../configs/endpoints';
import Bundles from '../pages/Bundles';
import Challenges from '../pages/Challenges';
import Dashboard from '../pages/Dashboard';
import Flashcards from '../pages/Flashcards';
import Users from '../pages/Users';
import Lessons from '../pages/Lessons';
import UserDetails from '../pages/UsersDetails';
import Campaigns from '../pages/Campaigns';
import StudyLogs from '../pages/StudyLogs';

const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: <PieChartOutlined />,
    component: Dashboard,
  },
  {
    path: endpoints.LOCAL_CHALLENGES_ENDPOINT,
    name: 'Challenges',
    icon: <FormOutlined />,
    component: Challenges,
    exact: true,
  },
  {
    path: endpoints.LOCAL_BUNDLES_ENDPOINT,
    name: 'Bundles',
    icon: <SnippetsOutlined />,
    component: Bundles,
    exact: true,
  },
  {
    path: endpoints.LOCAL_LESSONS_ENDPOINT,
    name: 'Lessons',
    icon: <PieChartOutlined />,
    component: Lessons,
    exact: true,
  },
  {
    path: endpoints.LOCAL_FLASHCARDS_ENDPOINT,
    name: 'Flashcards',
    icon: <QuestionOutlined />,
    component: Flashcards,
    exact: true,
  },
  {
    path: endpoints.LOCAL_USERS_ENDPOINT,
    name: 'Users',
    icon: <UserSwitchOutlined />,
    component: Users,
    exact: true,
  },
  {
    path: endpoints.LOCAL_USERS_DETAILS_ENDPOINT,
    // name: 'Users',
    icon: <UserSwitchOutlined />,
    component: UserDetails,
    exact: true,
  },
  {
    path: '/campaigns',
    name: 'Campaigns',
    icon: <NotificationOutlined />,
    component: Campaigns,
    exact: true,
  },
  {
    path: '/study-log',
    name: 'Study Log',
    icon: <BookOutlined />,
    component: StudyLogs,
    exact: true,
  },
];

export default routes;
