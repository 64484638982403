import React from 'react';
import { default as DsBoard } from '../components/dashboard/Dashboard';
import Layout from '../components/layout/Layout';

export default function Dashboard() {
  return (
    <Layout>
      <DsBoard />
    </Layout>
  );
}
