/* eslint-disable no-console */
import * as endpoints from '../../configs/endpoints';
import { axioService, GET, POST } from '../../services/axioService';
import { mapFlashcardsServerToClient } from './mapper';

/**
 * Fetch all appointments for the current provider with pagination
 * @param request
 * @returns {Promise<*>}
 */
export const getFlashcards = async (request: any): Promise<any> => {
  const response = await axioService(
    GET,
    endpoints.SERVER_FLASHCARDS_ENDPOINT,
    request,
    true
  );

  return {
    data: mapFlashcardsServerToClient(response.data.data),
    meta: response.data.meta,
  };
};

export const updateFlashcard = async (
  request: any
): Promise<{
  data: any;
}> => {
  const response = await axioService(
    POST,
    endpoints.SERVER_FLASHCARD_UPDATE_ENDPOINT.replace(':id', request.id),
    request,
    true
  );
  return {
    data: response,
  };
};
