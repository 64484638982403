import React, { useState } from 'react';
import { Campaign } from '../../interfaces/campaign';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Typography,
  message,
} from 'antd';
import Styles from './Styles.module.scss';
import UserSelectionDropdown from '../input/UserSelectionDropdown';
import clsx from 'clsx';
import { sendCampaign } from '../../api/campaigns/request';
import { debounce } from 'lodash';

interface Props {
  campaign: Campaign;
  onComplete?: any;
}
const RunCampaign = (props: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sendTo, setSendTo] = useState('test');

  const Title = (
    <span>
      Run{' '}
      <Typography.Text type="warning">
        <strong>{props.campaign.title}</strong>
      </Typography.Text>
    </span>
  );
  const [form] = Form.useForm();

  const onFinish = (request: any) => {
    setLoading(true);
    // eslint-disable-next-line no-console
    console.log('form data', request);
    sendCampaign({ ...request, id: props.campaign.id });
    setLoading(false);
    setShowModal(false);
    form.resetFields();
    message.success('Campaign is sent successfully.');
  };

  return (
    <>
      <Button onClick={() => setShowModal(true)} type="text">
        {' '}
        Run
      </Button>
      {showModal && (
        <Modal
          title={Title}
          open={showModal}
          onCancel={() => setShowModal(false)}
          onOk={() => setShowModal(false)}
          footer={null}
          width={'60%'}
        >
          <div className={clsx(Styles.pt0, 'bg-white form__content pt-0')}>
            <Form
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              form={form}
              name="control-hooks"
              onFinish={onFinish}
            >
              <Row gutter={15}>
                <Col lg={24} md={24} sm={24} xs={24}>
                  <Form.Item name="message" label="Message">
                    <Input.TextArea
                      rows={5}
                      placeholder={props.campaign.message}
                      value={props.campaign.message}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col lg={24} md={24} sm={24} xs={24}>
                  <Form.Item
                    name="send_to"
                    label="Send To"
                    rules={[{ required: true }]}
                  >
                    <Radio.Group
                      onChange={(e) => setSendTo(e.target.value)}
                      value={sendTo}
                    >
                      <Radio value={'test_users'}>Test Users</Radio>
                      <Radio value={'all'}>Send to All</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                {sendTo == 'test_users' && (
                  <Col lg={24} md={24} sm={24} xs={24}>
                    <Form.Item
                      name="user_ids"
                      label="Select Users"
                      rules={[{ required: true }]}
                    >
                      <UserSelectionDropdown
                        onChange={(val: any) => {
                          form.setFieldValue('user_ids', val);
                        }}
                        onClear={() => form.setFieldValue('user_ids', [])}
                        mode="multiple"
                      />
                    </Form.Item>
                  </Col>
                )}

                {sendTo === 'all' && (
                  <Col lg={24} md={24} sm={24} xs={24}>
                    <Form.Item
                      name="confirm"
                      valuePropName="checked"
                      rules={[
                        {
                          validator: (_, value) =>
                            value
                              ? Promise.resolve()
                              : Promise.reject(
                                  new Error(
                                    'Must confirm to send to All users.'
                                  )
                                ),
                        },
                      ]}
                    >
                      <Checkbox>
                        Confirm to send notification to All users.
                      </Checkbox>
                    </Form.Item>
                  </Col>
                )}
                <Col lg={24} md={24} sm={24} xs={24}>
                  <Form.Item>
                    <Button loading={loading} type="primary" htmlType="submit">
                      {'Submit'}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </Modal>
      )}
    </>
  );
};

export default RunCampaign;
