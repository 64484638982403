/* eslint-disable no-console */
import * as endpoints from '../../configs/endpoints';
import { axioService, GET, POST } from '../../services/axioService';
import { mapBundlesServerToClient } from './mapper';

/**
 * Get all patients
 * @param page the number of page
 * @returns {Promise<CreatePatientResponse[]>} a promise of array of patients with pagination
 */
export const getBundles = async (request: any): Promise<any> => {
  const response = await axioService(
    GET,
    endpoints.SERVER_BUNDLES_ENDPOINT,
    request,
    true
  );
  return {
    data: mapBundlesServerToClient(response.data.data),
    meta: response.data.meta,
  };
};

export const updateBundle = async (
  request: any
): Promise<{
  data: any;
}> => {
  const response = await axioService(
    POST,
    endpoints.SERVER_BUNDLE_UPDATE_ENDPOINT.replace(':id', request.id),
    request,
    true
  );
  return {
    data: response,
  };
};

export const deleteBundle = async (
  request: any
): Promise<{
  data: any;
}> => {
  const response = await axioService(
    POST,
    endpoints.SERVER_BUNDLE_DELETE_ENDPOINT.replace(':id', request.id),
    request,
    true
  );
  return {
    data: response,
  };
};
export const uploadFile = async (
  request: any,
  id: any
): Promise<{
  data: any;
}> => {
  const response = await axioService(
    POST,
    endpoints.SERVER_BUNDLE_FILE_UPLOAD_ENDPOINT.replace(':id', id),
    request,
    true
  );
  return {
    data: response,
  };
};
export const createBundle = async (
  request: any
): Promise<{
  data: any;
}> => {
  const response = await axioService(
    POST,
    endpoints.SERVER_BUNDLE_CREATE_ENDPOINT,
    request,
    true
  );
  return {
    data: response,
  };
};

export const exportBundle = async (request: any, id: any) => {
  const response = await axioService(
    POST,
    endpoints.SERVER_BUNDLE_EXPORT_ENDPOINT.replace(':id', id),
    request,
    true
  );
  console.log('export url', response);
  window.open(response.data, '_blank')?.focus();
};
