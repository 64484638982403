import React from 'react';
import Layout from '../components/layout/Layout';
import CampaignList from '../components/campaigns';

const Campaigns = () => {
  return (
    <Layout>
      <CampaignList />
    </Layout>
  );
};

export default Campaigns;
