/* eslint-disable no-console */
/* eslint-disable jsx-a11y/anchor-is-valid */
import type { ActionType, ProColumns } from '@ant-design/pro-components';
import { ProTable } from '@ant-design/pro-components';
import { Modal, Pagination, Space, Tooltip } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { getChallenges } from '../../api/challenges/request';
import Filter from './Filter';
import FlashcardFormContent from '../FlashcardForm/FlashcardFormContent';
import { ChallengeStatus } from '../../enums/status';
import { Challenge } from '../../interfaces/challenge';
import ApprovalForm from './ApprovalForm';
import { strLimit } from '../../services/utility';
import './ChallengeList.scss';
import { EditOutlined, FlagOutlined } from '@ant-design/icons';

const ChallengeList = () => {
  const actionRef = useRef<ActionType>();
  const [challenges, setChallenges] = useState<Array<Challenge>>([]);
  const [meta, setMeta] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [editingChallenge, setEditingChallenge] = useState<any>(null);
  const [approvalChallenge, setaApprovalChallenge] = useState<any>(null);
  const ACCEPT = 1;
  const REJECT = 2;
  const PENDING = 0;

  const [filterParams, setFilterParams] = useState({
    start_date: moment().format('YYYY-MM-DD'),
    page: 1,
    per_page: 30,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    fetchAppointments(filterParams);
  }, []);

  const fetchAppointments = async (requestParam: any) => {
    setLoading(true);
    try {
      const response = await getChallenges(requestParam);
      setChallenges(response.data);
      setMeta(response.meta);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const getStatusLabel = (status: any) => {
    switch (status) {
      case ChallengeStatus.Pending:
        return 'Pending';
      case ChallengeStatus.Resolved:
        return 'Accepted';
      case ChallengeStatus.Rejected:
        return 'Rejected';
      case ChallengeStatus.Canceled:
        return 'Canceled';

      default:
        break;
    }
  };

  const getStatusColor = (status: any) => {
    switch (status) {
      case ChallengeStatus.Pending:
        return '';
      case ChallengeStatus.Resolved:
        return 'green';
      case ChallengeStatus.Rejected:
        return 'red';
      case ChallengeStatus.Canceled:
        return 'yellow';

      default:
        break;
    }
  };

  const getStatusDetails = (record: Challenge) => {
    return (
      <div style={{ color: '#959595' }}>
        <div>
          <span style={{ color: '#000', fontWeight: '500' }}>Status:</span>{' '}
          <span style={{ color: record.statusObj.color }}>
            {record.statusObj.label}
          </span>
          <br />
          <span style={{ color: '#000', fontWeight: '500' }}>
            Challenged At:
          </span>{' '}
          {moment(record.challengedAt).format('YYYY-MM-DD HH:mm')}
        </div>
        <div>
          <span style={{ color: '#000', fontWeight: '500' }}>
            Approved by :
          </span>{' '}
          {record.approvedBy?.name}
        </div>
        <div>
          <span style={{ color: '#000', fontWeight: '500' }}>Remarks :</span>{' '}
          {record.remarks}
        </div>
      </div>
    );
  };

  const columns: ProColumns<any>[] = [
    {
      title: '#',
      render: (text, record, index) =>
        `${
          meta.current_page * meta.per_page - meta.per_page + index + 1
        }`.padStart(2, '0'),
      onCell: () => {
        return {
          style: { textAlign: 'center' },
        };
      },
    },
    {
      title: 'Title',
      dataIndex: 'title',
      sorter: (a, b) => `${a.flashcard.title}`.localeCompare(b.flashcard.title),
      render: (text, record, index) => {
        const title: string = record.flashcard.title;
        return (
          <div title={title} style={{ width: '250px' }}>
            {title.substring(0, 40) + (title.length > 40 ? '...' : '')}
            <p className="TextMeta">
              <small>{record.flashcard.bundle_title}</small>
            </p>
          </div>
        );
      },
    },
    {
      title: 'Suggestion',
      dataIndex: 'options',
      render: (text, record: Challenge, index) => {
        const opts: string = record.expectedAnswer as string;

        return (
          <Tooltip title={opts.length > 30 ? opts : ''}>
            {strLimit(opts, 30)}
            <p className="TextMeta">
              <small>{record.typeLabel}</small>
            </p>
          </Tooltip>
        );
      },
    },
    {
      title: 'Challenged By',
      dataIndex: 'challenged_by',
      render: (text, record, index) => {
        return (
          <Tooltip title={`Mobile: ${record.user?.mobile}`}>
            {record.user?.name || ''}
            <p className="TextMeta">
              <small>{record.user?.mobile}</small>
            </p>
          </Tooltip>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text, record, index) => (
        <Tooltip color={'#fff'} title={getStatusDetails(record)}>
          <div style={{ color: record.statusObj.color }}>
            {record.statusObj.label}
          </div>
        </Tooltip>
      ),
    },
    {
      title: 'Actions',
      valueType: 'option',
      hideInSetting: true,
      key: 'option',
      render: (text, record, index) => (
        <Space size="middle">
          <a
            onClick={() => {
              setEditingChallenge(record);
              setIsModalVisible(true);
            }}
          >
            <EditOutlined />
          </a>
          {record.status === ChallengeStatus.Pending ? (
            <>
              <a
                onClick={() =>
                  setaApprovalChallenge({
                    ...record,
                    status: ACCEPT,
                    action: 'Accept',
                  })
                }
              >
                Accept
              </a>
              <a
                style={{ color: 'red' }}
                onClick={() =>
                  setaApprovalChallenge({
                    ...record,
                    status: REJECT,
                    action: 'Reject',
                  })
                }
              >
                Reject
              </a>
            </>
          ) : (
            <a
              className="action-pending"
              style={{ color: 'red' }}
              onClick={() =>
                setaApprovalChallenge({
                  ...record,
                  status: PENDING,
                  action: 'Undo as Pending',
                })
              }
            >
              Pending
            </a>
          )}
          {/* <a>Delete</a> */}
        </Space>
      ),
    },
  ];
  const updateFlashcard = (flashcard: any) => {
    const items = challenges.map((item: any) => {
      if (item.flashcard?.id === flashcard.id) {
        return { ...item, ...flashcard };
      }
      return item;
    });

    setChallenges(items);
    setIsModalVisible(false);
  };

  const updateChallenge = (challenge: any) => {
    const items = challenges.map((item: any) => {
      if (item?.id === challenge.id) {
        return { ...item, ...challenge };
      }
      return item;
    });

    setChallenges(items);
    setIsModalVisible(false);
  };
  const onPaginateChange = (value: number) => {
    console.log('Page no', value);
    const params = { ...filterParams, page: value };
    console.log('Page no', value, params);

    setFilterParams(params);
    fetchAppointments(params);
  };
  const onFilter = (filter: any) => {
    setFilterParams({ ...filterParams, ...filter });
    fetchAppointments({ ...filterParams, ...filter });
    console.log({ ...filterParams, ...filter });
  };

  return (
    <div>
      <Filter onFilter={onFilter} types={meta?.types} />
      <ProTable<any>
        dataSource={challenges}
        columns={columns}
        actionRef={actionRef}
        search={false}
        pagination={false}
        rowKey="id"
        cardBordered
        loading={loading}
        size="small"
        className="challenges-table"
        options={{
          density: false,
          fullScreen: false,
          reload: false,
          setting: false,
        }}
      />
      <div className="list-pagination">
        <Pagination
          onChange={onPaginateChange}
          defaultCurrent={1}
          total={meta?.total || 0}
          pageSize={meta?.per_page || 15}
          showSizeChanger={false}
        />
      </div>
      <Modal
        title={
          <>
            <h2 style={{ color: '#595959' }}>
              {editingChallenge?.flashcard?.title || 'Edit flashcard'}
            </h2>
            <div>
              <small style={{ color: '#8c8c8c' }}> Original</small>:{' '}
              <span style={{ color: '#595959' }}>
                {editingChallenge?.originalAnswer}
              </span>
            </div>
            <div>
              <small style={{ color: '#8c8c8c' }}> Expected</small>:
              <span style={{ color: '#595959' }}>
                {' '}
                {editingChallenge?.expectedAnswer}
              </span>
            </div>
          </>
        }
        open={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        width="60%"
        footer={false}
      >
        <FlashcardFormContent
          flashcard={editingChallenge?.flashcard}
          shortForm={true}
          showTitle={false}
          onUpdate={updateFlashcard}
        />
      </Modal>

      <Modal
        title={
          <>
            <h2 style={{ color: '#595959' }}>
              {approvalChallenge?.flashcard?.title || 'Edit flashcard'}
            </h2>
          </>
        }
        open={approvalChallenge?.id}
        onOk={() => setaApprovalChallenge(null)}
        onCancel={() => setaApprovalChallenge(null)}
        width="60%"
        footer={false}
      >
        <ApprovalForm
          challenge={approvalChallenge}
          onApprove={(item: any) => {
            updateChallenge(item);
            setaApprovalChallenge(null);
          }}
          submitBtnLabel={approvalChallenge?.action || ''}
          onCancel={() => setaApprovalChallenge(null)}
        />
      </Modal>
    </div>
  );
};

export default ChallengeList;
