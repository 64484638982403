import React from 'react';
import { Card, Tooltip } from 'antd';
import Meta from 'antd/lib/card/Meta';
import style from './CardItem.module.scss';
import { DailyCount, StudyLogCount } from './dashboard.d';

interface CardItemProps {
  dailyCount?: DailyCount | StudyLogCount;
  label: string;
  icon?: any;
  hint?: string;
}
const CardItem = (props: CardItemProps) => {
  return (
    <>
      <Card style={{ width: '100%' }} headStyle={{ border: 'none' }}>
        <h1 style={{ textAlign: 'center' }}>
          <Tooltip title={props.hint || props.label}>
            {props.dailyCount?.total || 0}
          </Tooltip>
        </h1>
        <Meta description={props.label} style={{ justifyContent: 'center' }} />
      </Card>
    </>
  );
};

export default CardItem;
