import { Alert, Avatar, Dropdown } from 'antd';
import { Store } from 'antd/lib/form/interface';
import React from 'react';
import { connect } from 'react-redux';
import { SERVER_SEND_EMAIL_ENDPOINT } from '../../configs/endpoints';
import { axioService, POST } from '../../services/axioService';
import { FlexObj, getSessionUserInfo } from '../../store/ducks/session';
import AccountLogoutDropdown from '../accountLogoutDropdown/AccountLogoutDropdown';
import AppsDropdown from '../appsDropdown/AppsDropdown';
import './Header.scss';

interface HeaderProps {
  userInfo: FlexObj;
}

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { userInfo } = props;
  const [isEmailSend, setIsEmailSend] = React.useState<boolean>(false);

  const sendVerificationEmail = async () => {
    try {
      await axioService(POST, SERVER_SEND_EMAIL_ENDPOINT, {}, true);
      setIsEmailSend(true);
    } catch (Exception) {
      console.error(Exception);
    }
  };

  return (
    <div className="Header-container">
      <div className="Header-content">
        <div className="Header-main-content">
          <div className="Header-content-title">User Menu</div>
          <Dropdown
            trigger={['click']}
            // overlay={<AccountLogoutDropdown />}
            placement="bottomRight"
          >
            <div className="Header-profile">
              <span className="profile-name"> {userInfo?.name}</span>
              <Avatar src={userInfo.avatar}>
                <div>{userInfo?.name?.slice(0, 2)}</div>
              </Avatar>
            </div>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

/** connect the component to the store */

/** Interface to describe props from mapStateToProps */
interface DispatchedStateProps {
  userInfo: FlexObj;
}

/** Map props to state  */
const mapStateToProps = (state: Partial<Store>): DispatchedStateProps => {
  const result = {
    userInfo: getSessionUserInfo(state),
  };
  return result;
};

/** map props to actions */
const mapDispatchToProps = {};

/** connect Header to the redux store */
const ConnectedHeader = connect(mapStateToProps, mapDispatchToProps)(Header);

export default ConnectedHeader;
