import React from 'react';
import Layout from '../components/layout/Layout';
import UserList from '../components/users/UserList';
import Details from '../components/users/Details';

const UserDetails = () => {
  return (
    <Layout>
      <Details />
    </Layout>
  );
};

export default UserDetails;
