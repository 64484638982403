import { ProColumns, ProTable } from '@ant-design/pro-components';
import React, { useEffect, useState } from 'react';
import { getUser } from '../../api/users/request';
import { User } from '../../interfaces/user';
import moment from 'moment';
import BundleSelectionDropdown from '../input/BundleSelectionDropdown';
import { Col, QRCode, Row, Tabs, Tooltip } from 'antd';
import styles from './Styles.module.scss';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  EyeFilled,
  MailOutlined,
  PhoneFilled,
  SafetyOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Avatar, Card, Typography } from 'antd';
// @ts-ignore
import CopyToClipboard from 'react-copy-to-clipboard';
import { debounce } from 'lodash';
import QuizList from '../quizzes/List';
import PointList from '../points/List';
import Bundles from './Bundles';
import Profile from './Profile';
import StudyLog from '../studies';
const { Meta } = Card;
const { Text } = Typography;

const Details = () => {
  const slNo = 1;
  const params = useParams();
  const userId: number = parseInt(params.id || '0');
  const [user, setUser] = useState<User>();
  const [copied, setCopied] = useState(false);
  const [queryParams, setQueryParams] = useSearchParams();
  // eslint-disable-next-line no-console
  console.log('CurrentTab', queryParams.get('tab'), params);

  useEffect(() => {
    fetchUser(userId);
  }, [userId]);

  const fetchUser = async (userId: number) => {
    const response = await getUser({ id: userId });
    setUser(response.data);
  };
  // e

  const columns: ProColumns<User>[] = [
    {
      title: 'Name',
      hideInTable: true,
      dataIndex: 'q',
      fieldProps: {
        placeholder: 'Name/mobile/email',
      },
    },
    {
      title: 'SL#',
      hideInSearch: true,
      render: (_text, _record, index) => `${slNo + index}`.padStart(2, '0'),
    },
    {
      title: 'Name',
      hideInSearch: true,
      dataIndex: 'name',
    },
    {
      title: 'Mobile',
      hideInSearch: true,
      dataIndex: 'mobile',
    },
    {
      title: 'Registered',
      render: (text, record) => {
        const date = moment(record.registeredAt).format('DD-MMM-yyyy');
        const dateTime = moment(record.registeredAt).format(
          'DD-MMM-yyyy, hh:mm A'
        );
        return <span title={dateTime}>{date}</span>;
      },
      valueType: 'dateRange',
      search: {
        transform: (value) => {
          return {
            start_date: value[0],
            end_date: value[1],
          };
        },
      },
    },
    {
      title: <Tooltip title="Purchased bundles count"> Bundles</Tooltip>,
      hideInSearch: true,
      render: (text, record) => (
        <div className={styles.textRight}> {record.stats?.bundle || 0}</div>
      ),
    },
    {
      title: 'Quiz',
      hideInSearch: true,
      render: (text, record) => (
        <div className={styles.textRight}> {record.stats?.quiz || 0}</div>
      ),
    },
    {
      title: 'Points',
      hideInSearch: true,
      render: (text, record) => {
        const balance = record.pointsEarned - record.pointsSpent;
        return (
          <div className={styles.textRight}>
            {' '}
            <Tooltip
              title={`Point Earned: ${record.pointsEarned}, Spent: ${record.pointsSpent}`}
            >
              {balance}
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: 'Reading',
      hideInSearch: true,
      render: (text, record) => {
        return (
          <div className={styles.textRight}>
            <Tooltip
              title={`Strength: ${record.stats?.strength || 0}, Weakness: ${
                record.stats?.weakness || 0
              }`}
            >
              {`${record.stats?.read || 0}`.padStart(2, '0')}
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: 'Sort By',
      valueType: 'select',
      dataIndex: 'sort_by',
      hideInTable: true,
      valueEnum: {
        most_studied: { text: 'Most Studied' },
        most_read: { text: 'Most Read' },
        most_revised: { text: 'Most Revised' },
      },
    },
    {
      title: 'Bundles',
      dataIndex: 'bundle_ids',
      hideInTable: true,
      renderFormItem(schema, config, form, action) {
        return (
          <BundleSelectionDropdown
            mode="multiple"
            onChange={(value: any) => {
              form.setFieldValue('bundle_ids', value);
            }}
            onClear={() => {
              form.setFieldValue('bundle_ids', '');
            }}
          />
        );
      },
      fieldProps: {
        placeholder: 'Already enrolled.',
      },
    },
    {
      title: 'Bundles',
      dataIndex: 'bundle_ids_not_enrolled',
      hideInTable: true,
      renderFormItem(schema, config, form, action) {
        return (
          <BundleSelectionDropdown
            mode="multiple"
            onChange={(value: any) => {
              form.setFieldValue('bundle_ids_not_enrolled', value);
            }}
            onClear={() => {
              form.setFieldValue('bundle_ids_not_enrolled', '');
            }}
          />
        );
      },
      fieldProps: {
        placeholder: 'Not enrolled yet.',
      },
    },
  ];

  return (
    <>
      <Card
        style={{ width: '100%' }}
        actions={[
          <Tabs
            defaultActiveKey={queryParams.get('tab') || 'profile'}
            key={'user-profile'}
            onChange={(tab) => setQueryParams({ tab })}
            items={[
              {
                label: <span className={styles.px10}>Profile</span>,
                key: 'profile',
                children: <Profile user={user} />,
              },
              {
                label: <span>Bundles ({user?.stats?.bundle})</span>,
                key: 'bundles',
                children: <Bundles userId={userId} />,
              },
              {
                label: (
                  <span> Points({user?.pointsBalance?.toLocaleString()})</span>
                ),
                key: 'points',
                children: (
                  <PointList userId={userId} onAdd={() => fetchUser(userId)} />
                ),
              },
              {
                label: <span>Quiz ({user?.stats?.quiz})</span>,
                key: 'quiz',
                children: <QuizList userId={userId} />,
              },
              {
                label: <span>Study Log</span>,
                key: 'study-log',
                children: <StudyLog userId={userId} />,
              },
            ]}
          />,
        ]}
      >
        <Row>
          <Col md={18}>
            <Meta
              avatar={<Avatar src={user?.avatar} size={100} />}
              title={user?.name}
              description={
                <>
                  {user?.mobile && (
                    <Tooltip title={copied ? 'Copied!' : 'Click to Copy'}>
                      <PhoneFilled />
                      <CopyToClipboard
                        text={user.mobile}
                        onCopy={() => setCopied(true)}
                      >
                        <span
                          onMouseLeave={debounce(() => setCopied(false), 500)}
                        >
                          {' '}
                          {user.mobile}{' '}
                        </span>
                      </CopyToClipboard>
                    </Tooltip>
                  )}
                  {user?.email && (
                    <div>
                      <Tooltip title={copied ? 'Copied!' : 'Click to Copy'}>
                        <MailOutlined />
                        <CopyToClipboard
                          text={user.email}
                          onCopy={() => setCopied(true)}
                        >
                          <span
                            onMouseLeave={debounce(() => setCopied(false), 500)}
                          >
                            {' '}
                            {user.email}{' '}
                          </span>
                        </CopyToClipboard>
                      </Tooltip>
                    </div>
                  )}
                  <div className={styles.mt10}>
                    <Tooltip title="Total read" className={styles.pr10}>
                      {' '}
                      <EyeFilled /> {user?.stats?.read}
                    </Tooltip>
                    <Tooltip title="Strength" className={styles.pr10}>
                      {' '}
                      <SafetyOutlined /> {user?.stats?.strength}
                    </Tooltip>
                    <Tooltip title="Weakness">
                      {' '}
                      <WarningOutlined /> {user?.stats?.weakness}{' '}
                    </Tooltip>
                  </div>
                </>
              }
            />
          </Col>
          <Col md={6}>
            {user?.mobile && (
              <div>
                <Meta
                  avatar={
                    <div className={styles.textCenter}>
                      <QRCode size={120} value={`tel:${user.mobile}`} />
                      <Text type="secondary">Scan to Call</Text>
                    </div>
                  }
                />
              </div>
            )}
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default Details;
