/* eslint-disable no-console */
import { Bundle } from '../../interfaces/bundle';
import { getYMD } from '../../services/utility';

export const mapBundleItemServerToClient = (bundle: any): Bundle => {
  return {
    ...bundle,
    thumbnail: bundle.media.thumbnail,
    stats: {
      flashcardCount: bundle.stats?.flashcard_count,
      tagCount: bundle.stats?.tag_count,
      userCount: bundle.stats?.user_count,
    },
  };
};

export const mapBundlesServerToClient = (bundles: any[]): Bundle[] => {
  return bundles.map(mapBundleItemServerToClient);
};
