import React, { useRef } from 'react';
import { Button, Drawer, Popconfirm, Space, Typography } from 'antd';
import { ActionType, ProColumns, ProTable } from '@ant-design/pro-components';
import { getCampaigns } from '../../api/campaigns/request';
import { Campaign } from '../../interfaces/campaign';
import RunCampaign from './RunCampaign';

const CampaignDrawer = (props: any) => {
  let slNo = 1;
  const actionRef = useRef<ActionType>();

  const onSendConfirm = (record: Campaign) => {
    if (props.onSend) {
      props.onSend(record);
    }
  };

  const CampaignList = () => {
    const columns: ProColumns<Campaign>[] = [
      {
        title: 'SL#',
        dataIndex: 'sl',
        hideInSearch: true,
        render: (text, record, index) => slNo + index,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        render: (text, record) => {
          return (
            <>
              <Space direction="vertical">
                <Typography.Text>{record.title}</Typography.Text>
                <Typography.Text type="secondary">
                  {record.message}
                </Typography.Text>
              </Space>
            </>
          );
        },
      },

      {
        title: 'Action',
        dataIndex: 'action',
        hideInSearch: true,
        // width: '160px',
        render: (_text, record, index) => {
          return (
            <>
              {/* <EditCampaign campaign={record} onAdd={onAddOrUpdate} /> */}
              {record.type === 'notification' && record.status === 'active' && (
                <Popconfirm
                  title="Send this campaign"
                  description="Are you sure to delete this task?"
                  onConfirm={() => onSendConfirm(record)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button danger>Send</Button>
                </Popconfirm>
              )}
            </>
          );
        },
      },
    ];
    return (
      <>
        <ProTable
          columns={columns}
          rowKey={'id'}
          actionRef={actionRef}
          request={async (params, sorter, filter) => {
            params = {
              page: params.current,
              per_page: params.pageSize,
              type: 'notification',
              ...params,
            };
            // eslint-disable-next-line no-console
            const response = await getCampaigns(params);
            //   setSearchParams(params);

            slNo = ((params.current || 0) - 1) * (params.pageSize || 0) + 1;

            return {
              data: response.data,
              // Please return true for success.
              // otherwise the table will stop parsing the data, even if there is data
              success: true,
              // not passed will use the length of the data, if it is paged you must pass
              total: response.meta?.total,
            };
          }}
        />
      </>
    );
  };

  return (
    <Drawer
      title="Drawer with extra actions"
      placement={props.placement}
      width={'60%'}
      onClose={props.onClose}
      open={props.open}
      extra={
        <Space>
          <Button onClick={props.onClose}>Cancel</Button>
          <Button type="primary" onClick={props.onClose}>
            OK
          </Button>
        </Space>
      }
    >
      <CampaignList />
    </Drawer>
  );
};

export default CampaignDrawer;
