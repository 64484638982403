import React from 'react';

const LogoImgShort = () => (
  <svg
    width="200"
    height="50"
    viewBox="0 0 246 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ filter: 'brightness(.8)' }}
  >
    <path
      d="M235.295 53.3009C235.295 55.7574 236.829 57.3206 238.756 57.3206C239.91 57.3206 240.74 56.8042 241.176 56.0923V57.2089H243.582V46.8807H241.176V50.4956C240.698 49.7837 239.797 49.3092 238.756 49.3092C236.829 49.3092 235.295 50.8445 235.295 53.3009ZM241.176 53.3149C241.176 54.5571 240.374 55.241 239.46 55.241C238.559 55.241 237.743 54.5431 237.743 53.3009C237.743 52.0588 238.559 51.3888 239.46 51.3888C240.374 51.3888 241.176 52.0727 241.176 53.3149Z"
      fill="white"
    ></path>
    <g clipPath="url(#clip0_1485_2737)">
      <path
        d="M42.2372 63.2878C44.6031 59.7594 45.6332 55.834 49.1537 53.0771C62.9868 42.0949 62.0812 20.7659 47.5689 10.7027C47.1388 10.3964 46.652 10.1468 45.8936 9.69295V22.1727C45.8936 28.4126 45.8936 34.6524 45.8936 40.9037V42.6395V43.7059C45.9962 44.1281 45.9677 44.5716 45.8119 44.9771C45.6562 45.3826 45.3805 45.7308 45.0219 45.975C43.99 46.5552 42.7806 46.733 41.6259 46.4742C40.3582 46.3048 39.1585 45.7996 38.1506 45.0106C37.7884 44.8745 37.2111 44.0349 37.2111 44.0349C36.8602 43.6719 36.9394 42.5941 36.9394 42.5941V41.2554C36.911 40.8474 36.911 40.438 36.9394 40.0301V6.27804H27.9966V8.72861C27.9966 19.62 27.906 30.5114 28.0645 41.4028V43.0366C28.0645 43.0366 28.155 44.1711 27.7928 44.4774C27.7928 44.4774 27.2155 45.3283 26.8532 45.4644C25.8454 46.2534 24.6457 46.7586 23.378 46.928C22.2232 47.1835 21.0149 47.0059 19.982 46.4288C19.6209 46.1868 19.3434 45.8389 19.1873 45.4327C19.0313 45.0265 19.0044 44.5819 19.1103 44.1597C19.0998 43.9935 19.0998 43.8268 19.1103 43.6605C19.1103 33.1208 19.1103 22.5811 19.1103 12.0528V9.77237C18.4651 10.1241 17.9783 10.3283 17.5595 10.6233C14.1872 12.9235 11.3924 15.9755 9.39429 19.5398C7.39618 23.1041 6.24906 27.0838 6.04289 31.1669C5.83673 35.25 6.57711 39.3254 8.20599 43.0737C9.83486 46.8219 12.3079 50.141 15.4313 52.7708C17.254 54.2339 18.7275 56.0867 19.7443 58.1938C20.5367 59.8615 21.4536 61.4725 22.3365 63.1516C10.6316 61.9263 -0.597863 45.5438 0.0247396 30.8404C0.445402 22.5736 3.9983 14.7793 9.95819 9.04859C15.9181 3.31792 23.8355 0.0829851 32.0944 0.00413138C49.3462 -0.268154 63.4962 12.949 64.9226 30.6362C66.0546 44.8745 54.5987 61.5746 42.2372 63.2878Z"
        fill="url(#paint0_linear_1485_2737)"
      ></path>
      <path
        d="M44.8748 51.0578C42.3051 55.7774 39.9619 60.1339 37.562 64.4338C37.4163 64.6511 37.2256 64.8345 37.003 64.9716C36.7803 65.1086 36.5309 65.196 36.2715 65.2279C33.6226 65.296 30.9851 65.2279 28.3475 65.2279C28.0925 65.1764 27.8515 65.0708 27.6406 64.9182C27.4297 64.7655 27.2539 64.5694 27.1249 64.343C24.7477 60.0772 22.4271 55.7887 19.9141 51.1599C20.8536 51.1599 21.5441 51.1599 22.2347 51.1599C24.7364 51.0805 26.6268 49.7077 28.506 48.2555C31.3926 45.9864 33.5887 45.9864 36.43 48.2555C38.7846 50.0821 41.2976 51.4775 44.8748 51.0578Z"
        fill="url(#paint1_linear_1485_2737)"
      ></path>
      <path
        d="M32.3774 74.0319L29.2078 68.1777H35.5017L32.3774 74.0319Z"
        fill="url(#paint2_linear_1485_2737)"
      ></path>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1485_2737"
        x1="-0.5"
        y1="29.4931"
        x2="65.9986"
        y2="30.078"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0090E1"></stop>
        <stop offset="1" stopColor="#1269F9"></stop>
      </linearGradient>
      <linearGradient
        id="paint1_linear_1485_2737"
        x1="19.7221"
        y1="55.2703"
        x2="45.2569"
        y2="55.5621"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0090E1"></stop>
        <stop offset="1" stopColor="#1269F9"></stop>
      </linearGradient>
      <linearGradient
        id="paint2_linear_1485_2737"
        x1="29.1594"
        y1="70.9059"
        x2="35.5984"
        y2="70.9651"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0090E1"></stop>
        <stop offset="1" stopColor="#1269F9"></stop>
      </linearGradient>
      <clipPath id="clip0_1485_2737">
        <rect width="65" height="74" fill="white"></rect>
      </clipPath>
    </defs>
  </svg>
);

export default LogoImgShort;
