import { Campaign } from '../../interfaces/campaign';

export const mapItemServerToClient = (item: any): Campaign => {
  return {
    ...item,
    contextType: item.context_type,
    contextId: item.context_id,
    ctaUrl: item.cta_url,
    startAt: item.start_at,
    endAt: item.end_at,
    createdAt: item.created_at,
    updatedAt: item.updated_at,
  };
};

export const mapItemListServerToClient = (
  data: Array<any>
): Array<Campaign> => {
  return data.map(mapItemServerToClient);
};
