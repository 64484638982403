import * as endpoints from '../../configs/endpoints';
import { MetaInterface } from '../../interfaces/api';
import { axioService, GET, POST } from '../../services/axioService';
import { getProviderId } from '../../services/utility';
import { mapMeta } from '../util';
import { mapsFromServerToClient } from './mapper';

interface ResponseInterface {
  data: Array<any> | any | null | any;
  meta?: MetaInterface | null;
}

/**
 * Fetch all users for the current provider with pagination
 * @param request - The request object
 * @returns {Promise<ResponseInterface>}
 */
export const getQuizzes = async (request?: any): Promise<ResponseInterface> => {
  const response = await axioService(
    GET,
    endpoints.SERVER_QUIZZES_ENDPOINT,
    request,
    true
  );
  return {
    meta: mapMeta(response.data.meta),
    data: mapsFromServerToClient(response.data.data),
  };
};
