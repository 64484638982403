import React from 'react';
import { profile } from 'console';
import { List } from 'antd';
import styles from './Styles.module.scss';
interface Props {
  user: any;
}
const Profile = (props: Props) => {
  const user = props.user;
  return (
    <div className={styles.px10}>
      {user && (
        <List
          itemLayout="vertical"
          dataSource={[props.user]}
          renderItem={(item, index) => (
            <List.Item key={'gender'} className={styles.textLeft}>
              <List.Item.Meta
                title={'Gender'}
                description={user?.gender || '--'}
              />
              <List.Item.Meta
                title={'Goals'}
                description={(user?.goals || []).join(', ')}
              />
              <List.Item.Meta
                title={'Degree'}
                description={user?.degree || '--'}
              />
              <List.Item.Meta
                title={'Last Institute'}
                description={user?.last_institute || '--'}
              />
              <List.Item.Meta
                title={'Prefer Tags'}
                description={(user?.tags || []).join(', ')}
              />
            </List.Item>
          )}
        />
      )}
    </div>
  );
};
export default Profile;
