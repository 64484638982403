import React from 'react';
import { Card, Tooltip } from 'antd';
import { Column } from '@ant-design/plots';
import { config } from 'process';

interface Props {
  config: any;
  title: string;
}
const BarChart = (props: Props) => {
  return (
    <>
      <Card>
        <Column {...props.config} />
        <h3
          style={{ textAlign: 'center', marginTop: '20px' }}
          className="TextMeta"
        >
          {props.title}
        </h3>
      </Card>
    </>
  );
};

export default BarChart;
