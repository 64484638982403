/** local */

/** local login endpoint */
export const LOCAL_LOGIN_ENDPOINT = `/login`;

/** local register endpoint */
export const LOCAL_REGISTER_ENDPOINT = `/register`;

/** local forgot password endpoint */
export const LOCAL_FORGOT_PASSWORD_ENDPOINT = `/forgot-password`;

/** local reset password endpoint */
export const LOCAL_RESET_PASSWORD_ENDPOINT = `/reset-password`;

/** local logout endpoint */
export const LOCAL_LOGOUT_ENDPOINT = `/logout`;

/** local logout endpoint */
export const LOCAL_AUTHORIZE_ENDPOINT = `/authorize`;

/** local verify email endpoint */
export const LOCAL_VERIFY_EMAIL_ENDPOINT = `/email/verify/:id/:hash`;

/** local personal info endpoint */
export const LOCAL_PERSONAL_INFO_ENDPOINT = `/account`;

export const LOCAL_DASHBOARD_ENDPOINT = `/dashboard`;
export const LOCAL_CHALLENGES_ENDPOINT = `challenges`;
export const LOCAL_FLASHCARDS_ENDPOINT = `flashcards`;

export const LOCAL_USERS_ENDPOINT = `/users`;
export const LOCAL_USERS_DETAILS_ENDPOINT = `/users/:id`;
export const LOCAL_USERS_CREATE_ENDPOINT = `/users/create`;
export const LOCAL_USERS_EDIT_ENDPOINT = `/users/:id/edit`;

export const LOCAL_REPORTS_ENDPOINT = `/reports`;
export const LOCAL_BUNDLES_ENDPOINT = '/bundles';
export const LOCAL_LESSONS_ENDPOINT = '/lessons';
/** server */

/** server register endpoint */
export const SERVER_REGISTER_ENDPOINT =
  process.env.REACT_APP_REGISTER_USER_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/provider/signup`;

/** server login endpoint */
export const SERVER_LOGIN_ENDPOINT =
  process.env.REACT_APP_LOGIN_USER_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/oauth/token`;

/** server login endpoint */
export const SERVER_LOGOUT_ENDPOINT =
  process.env.REACT_APP_LOGOUT_USER_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/logout`;

/** server forgot password endpoint */
export const SERVER_FORGOT_PASSWORD_ENDPOINT =
  process.env.REACT_APP_FORGOT_PASSWORD_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/forgot-password`;

/** server reset password endpoint */
export const SERVER_RESET_PASSWORD_ENDPOINT =
  process.env.REACT_APP_RESET_PASSWORD_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/reset-password`;

/** server change password endpoint */
export const SERVER_CHANGE_PASSWORD_ENDPOINT =
  process.env.REACT_APP_CHANGE_PASSWORD_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/change-password`;

/** server profile endpoint */
export const SERVER_USER_PROFILE_ENDPOINT =
  process.env.REACT_APP_USER_PROFILE_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/profile/basic`;

/** server email verify endpoint */
export const SERVER_EMAIL_VERIFY_ENDPOINT =
  process.env.REACT_APP_EMAIL_VERIFY_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/email/verify`;

/** server send email endpoint */
export const SERVER_SEND_EMAIL_ENDPOINT =
  process.env.REACT_APP_SEND_EMAIL_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/email/verification-notification`;

/** server avatar endpoint */
export const SERVER_AVATAR_ENDPOINT =
  process.env.REACT_APP_AVATAR_ENDPOINT ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/profile/change-avatar`;

/** google auth endpoint */
export const SERVER_GOOGLE_AUTH_LINK =
  process.env.REACT_APP_GOOGLE_AUTH_LINK ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/login/google`;

/** facebook auth endpoint */
export const SERVER_FACEBOOK_AUTH_LINK =
  process.env.REACT_APP_FACEBOOK_AUTH_LINK ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/login/facebook`;

/** apple auth endpoint */
export const SERVER_APPLE_AUTH_LINK =
  process.env.REACT_APP_APPLE_AUTH_LINK ||
  `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/login/apple`;

/** apps */

/** Todo app endpoint */
export const TODO_APP_ENDPOINT = process.env.REACT_APP_TODO_ENDPOINT || '';

/** Tips app endpoint */
export const TIPS_APP_ENDPOINT = process.env.REACT_APP_TIPS_ENDPOINT || '';

/** sample app authorize endpoint */
export const APP_AUTHORIZE_ENDPOINT =
  process.env.REACT_APP_AUTHORIZE_ENDPOINT || '';

/** external */
export const BLOG_ENDPOINT = process.env.REACT_APP_BLOG_ENDPOINT || '';
export const TERMS_OF_SERVICE_ENDPOINT =
  process.env.REACT_APP_TERMS_OF_SERVICE_ENDPOINT || '/terms-of-service/';
export const PRIVACY_POLICY_ENDPOINT =
  process.env.REACT_APP_PRIVACY_POLICY_ENDPOINT || '/privacy-policy/';
export const VIRTUNUS_HOME_ENDPOINT =
  process.env.REACT_APP_VIRTUNUS_HOME_ENDPOINT || '';

// Dashboard API endpoints
export const SERVER_ADMIN_DASHBOARD_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/dashboard`;
// Bundle API endpoints
export const SERVER_BUNDLES_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/bundles`;
export const SERVER_BUNDLE_FILE_UPLOAD_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/bundles/:id/import`;
export const SERVER_BUNDLE_EXPORT_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/bundles/:id/export-url`;
export const SERVER_BUNDLE_UPDATE_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/bundles/:id`;
export const SERVER_BUNDLE_DELETE_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/bundles/:id/delete`;
export const SERVER_BUNDLE_CREATE_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/bundles`;

// Flashcard API endpoints
export const SERVER_FLASHCARDS_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/flashcards`;
export const SERVER_FLASHCARD_UPDATE_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/flashcards/:id`;

// Challenge API endpoints
export const SERVER_CHALLENGE_MARK_AS_RESOLVED_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/challenges/:id/status`;
export const SERVER_CHALLENGE_MARK_AS_REJECTED_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/challenges/:id/reject`;
export const SERVER_CHALLENGES_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/challenges`;
export const SERVER_CHALLENGERS_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/challengers`;

// Topic API endpoint
export const SERVER_TOPICS_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/topics`;

// Users API endpoints
export const SERVER_USERS_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/users`;
export const SERVER_USERS_STORE_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/users`;
export const SERVER_USER_SHOW_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/users/:userId`;
export const SERVER_USER_UPDATE_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/users/:userId`;
export const SERVER_USER_SEND_CAMPAIGN_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/users/send-campaign`;

// Lessons API endpoints
export const SERVER_LESSONS_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/lessons`;
export const SERVER_LESSON_UPDATE_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/lessons/:id`;
export const SERVER_LESSON_DELETE_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/lessons/:id/delete`;
export const SERVER_LESSON_CREATE_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/lessons`;

// Quiz API endpoint
export const SERVER_QUIZZES_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/quizzes`;
// Study Log API endpoint
export const SERVER_STUDY_LOG_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/study-log`;

// Point API endpoint
export const SERVER_POINTS_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/points`;

// Campaign API endpoints
export const SERVER_CAMPAIGNS_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/campaigns`;
export const SERVER_CAMPAIGN_CREATE_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/campaigns`;
export const SERVER_CAMPAIGN_UPDATE_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/campaigns/:id`;
export const SERVER_CAMPAIGN_DELETE_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/campaigns/:id/delete`;
export const SERVER_CAMPAIGN_SEND_ENDPOINT = `${process.env.REACT_APP_SERVER_ENDPOINT}/admin/campaigns/:id/send`;
