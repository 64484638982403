import React from 'react';
import Layout from '../components/layout/Layout';
import UserList from '../components/users/UserList';

const Users = () => {
  return (
    <Layout>
      <UserList />
    </Layout>
  );
};

export default Users;
