import { EditOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import CampaignForm from './CampaignForm';
import { Campaign } from '../../interfaces/campaign';

interface Props {
  campaign: Campaign;
  onAdd?: any;
}

const EditCampaign = (props: Props) => {
  const [showModal, setShowModal] = useState(false);
  const EditTitle = (
    <span>
      Edit <strong>{props.campaign.name}</strong> <EditOutlined />
    </span>
  );
  return (
    <>
      <Button type="text" onClick={() => setShowModal(true)}>
        <EditOutlined /> Edit
      </Button>
      <Modal
        title={EditTitle}
        open={showModal}
        onCancel={() => setShowModal(false)}
        width={'60%'}
        footer={null}
      >
        <CampaignForm
          onAdd={(record: any) => {
            setShowModal(false);
            props.onAdd(record);
          }}
          campaign={props.campaign}
        />
      </Modal>
    </>
  );
};

export default EditCampaign;
