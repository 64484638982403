import { Avatar, Select } from 'antd';
import { getUsers } from '../../api/users/request';
import React, { useState } from 'react';
import DropdownCard from '../dropdownCard/DropdownCard';
import { debounce } from 'lodash';

interface UserSelectionDropdownProps {
  onSelect?: any;
  onClear: any;
  onChange?: any;
  mode?: 'multiple' | 'tags';
}
const UserSelectionDropdown = (props: UserSelectionDropdownProps) => {
  const [users, setUsers] = useState([]);
  const fetchChallengers = debounce(async (q: any) => {
    const response = await getUsers({ q });
    if (q && response.data && response.data.length > 0) {
      setUsers(response.data);
    }
  }, 500);

  return (
    <div>
      <Select
        style={{ width: '100%' }}
        mode={props.mode}
        allowClear
        showSearch
        onSearch={fetchChallengers}
        onClear={props.onClear}
        onSelect={props.onSelect}
        onChange={props.onChange}
        placeholder="Search User"
        showArrow={false}
        filterOption={false}
        notFoundContent={''}
      >
        {users.map((itr: any, index: number) => (
          <Select.Option title={itr.name || ''} key={itr.id} value={itr.id}>
            <DropdownCard
              item={{
                photo: (
                  <Avatar
                    size={67}
                    shape="circle"
                    src={itr.avatar}
                    // icon={<UserOutlined />}
                  />
                ),
                title: itr.name,
                description: `${itr.mobile}`,
              }}
            />
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default UserSelectionDropdown;
