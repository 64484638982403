import { ProColumns, ProTable } from '@ant-design/pro-components';
import React, { useEffect, useState } from 'react';
import { getUsers, sendCampaignToUsers } from '../../api/users/request';
import { User } from '../../interfaces/user';
import moment from 'moment';
import BundleSelectionDropdown from '../input/BundleSelectionDropdown';
import { Button, Tooltip } from 'antd';
import styles from './Styles.module.scss';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import CampaignDrawer from '../campaigns/CampaignDrawer';
import { NotificationTwoTone, WarningOutlined } from '@ant-design/icons';
import { Campaign } from '../../interfaces/campaign';

const UserList = () => {
  let slNo = 1;
  const [drawer, setDrawer] = useState('');
  const [searchParams, setSearchParams] = useState({});
  // eslint-disable-next-line no-console

  const sendCampaign = (campaign: Campaign) => {
    sendCampaignToUsers({
      ...searchParams,
      campaign_id: campaign.id,
    });
  };

  const columns: ProColumns<User>[] = [
    {
      title: 'Name',
      hideInTable: true,
      dataIndex: 'q',
      fieldProps: {
        placeholder: 'Name/mobile/email',
      },
    },
    {
      title: 'SL#',
      hideInSearch: true,
      render: (_text, _record, index) => `${slNo + index}`.padStart(2, '0'),
    },
    {
      title: 'Name',
      hideInSearch: true,
      dataIndex: 'name',
    },
    {
      title: 'Mobile',
      hideInSearch: true,
      dataIndex: 'mobile',
    },
    {
      title: 'Registered',
      render: (text, record) => {
        const date = moment(record.registeredAt).format('DD-MMM-yyyy');
        const dateTime = moment(record.registeredAt).format(
          'DD-MMM-yyyy, hh:mm A'
        );
        return <span title={dateTime}>{date}</span>;
      },
      valueType: 'dateRange',
      search: {
        transform: (value) => {
          return {
            start_date: value[0],
            end_date: value[1],
          };
        },
      },
    },
    {
      title: <Tooltip title="Purchased bundles count"> Bundles</Tooltip>,
      hideInSearch: true,
      render: (text, record) => (
        <div className={styles.textRight}> {record.stats?.bundle || 0}</div>
      ),
    },
    {
      title: 'Quiz',
      hideInSearch: true,
      render: (text, record) => (
        <div className={styles.textRight}> {record.stats?.quiz || 0}</div>
      ),
    },
    {
      title: 'Points',
      hideInSearch: true,
      render: (text, record) => {
        const balance = record.pointsEarned - record.pointsSpent;
        return (
          <div className={styles.textRight}>
            {' '}
            <Tooltip
              title={`Point Earned: ${record.pointsEarned}, Spent: ${record.pointsSpent}`}
            >
              {balance}
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: 'Reading',
      hideInSearch: true,
      render: (text, record) => {
        return (
          <div className={styles.textRight}>
            <Tooltip
              title={`Strength: ${record.stats?.strength || 0}, Weakness: ${
                record.stats?.weakness || 0
              }`}
            >
              {`${record.stats?.read || 0}`.padStart(2, '0')}
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: 'Actions',
      hideInSearch: true,
      render: (text, record) => {
        return (
          <div>
            <Link to={`/users/${record.id}`}>Details</Link>
          </div>
        );
      },
    },
    {
      title: 'Sort By',
      valueType: 'select',
      dataIndex: 'sort_by',
      hideInTable: true,
      valueEnum: {
        most_studied: { text: 'Most Studied' },
        most_read: { text: 'Most Read' },
        most_revised: { text: 'Most Revised' },
      },
    },
    {
      title: 'Bundles',
      dataIndex: 'bundle_ids',
      hideInTable: true,
      renderFormItem(schema, config, form, action) {
        return (
          <BundleSelectionDropdown
            mode="multiple"
            onChange={(value: any) => {
              form.setFieldValue('bundle_ids', value);
            }}
            onClear={() => {
              form.setFieldValue('bundle_ids', '');
            }}
          />
        );
      },
      fieldProps: {
        placeholder: 'Already enrolled.',
      },
    },
    {
      title: 'Bundles',
      dataIndex: 'bundle_ids_not_enrolled',
      hideInTable: true,
      renderFormItem(schema, config, form, action) {
        return (
          <BundleSelectionDropdown
            mode="multiple"
            onChange={(value: any) => {
              form.setFieldValue('bundle_ids_not_enrolled', value);
            }}
            onClear={() => {
              form.setFieldValue('bundle_ids_not_enrolled', '');
            }}
          />
        );
      },
      fieldProps: {
        placeholder: 'Not enrolled yet.',
      },
    },
  ];

  return (
    <>
      {/* <ProTable columns={columns} dataSource={userList} /> */}
      <ProTable
        columns={columns}
        rowKey={'id'}
        request={async (params, sorter, filter) => {
          params = {
            page: params.current,
            per_page: params.pageSize,
            ...params,
          };
          // eslint-disable-next-line no-console
          const response = await getUsers(params);
          setSearchParams(params);

          slNo = ((params.current || 0) - 1) * (params.pageSize || 0) + 1;

          return {
            data: response.data,
            // Please return true for success.
            // otherwise the table will stop parsing the data, even if there is data
            success: true,
            // not passed will use the length of the data, if it is paged you must pass
            total: response.meta?.total,
          };
        }}
        toolBarRender={() => [
          <Button key={'notify'} onClick={() => setDrawer('notify')}>
            <NotificationTwoTone /> Notify
          </Button>,
        ]}
      />
      <CampaignDrawer
        open={drawer === 'notify'}
        onClose={() => setDrawer('')}
        placement="right"
        onSend={sendCampaign}
      />
    </>
  );
};

export default UserList;
