import { ProColumns, ProTable } from '@ant-design/pro-components';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { getUsers } from '../../api/users/request';
import { User } from '../../interfaces/user';
import { text } from 'stream/consumers';
import moment from 'moment';
import { deleteLesson, getLessons } from '../../api/lessons/request';
import ShortCard from '../shortCard/ShortCard';
import { Lesson } from '../../interfaces/lesson';
import { Button, Dropdown, Input, Modal, Space } from 'antd';
import BundleSelectionDropdown from '../input/BundleSelectionDropdown';
import { MenuProps } from 'antd/lib/menu';
import { DeleteOutlined } from '@ant-design/icons';
import LessonForm from './LessonForm';
import { LayoutContext } from '../layout/LayoutContext';
import CreateLesson from './CreateLesson';

const LessonList = () => {
  let slNo = 1;
  const [editingLesson, setEditingLesson] = useState<any>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const formRef = useRef();
  const { setPageMenus } = useContext(LayoutContext);
  useEffect(() => {
    setPageMenus([
      {
        component: CreateLesson,
      },
    ]);
  }, []);

  const DeleteLesson = (props: { record: Lesson }) => {
    const [deleteText, setDeleteText] = useState('');
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const record = props.record;
    const lessonId = props?.record.id;

    const onConfirm = () => {
      deleteLesson({ id: lessonId });
      setIsDeleteModalOpen(false);
      reloadData();
    };

    return (
      <div>
        <span
          style={{ cursor: 'pointer', color: 'red' }}
          onClick={() => setIsDeleteModalOpen(true)}
        >
          Delete
        </span>
        <Modal
          title={
            <div>
              Delete <em>{record?.title}</em> ?
            </div>
          }
          open={isDeleteModalOpen}
          onOk={() => setIsDeleteModalOpen(false)}
          onCancel={() => setIsDeleteModalOpen(false)}
          width="60%"
          footer={
            <Button
              onClick={onConfirm}
              type="dashed"
              disabled={deleteText === 'delete' ? false : true}
            >
              Confirm
            </Button>
          }
        >
          <Input
            placeholder="Type 'delete'"
            onChange={(ev) => setDeleteText(ev.target.value)}
          />
        </Modal>
      </div>
    );
  };

  const reloadData = () => {
    const form: any = formRef?.current;
    form?.submit();
  };

  const getDropdownOptions = (record: Lesson): MenuProps['items'] => {
    const options = [];

    if (!record.stats?.flashcardCount) {
      options.push({
        key: record.id + '2',
        label: <DeleteLesson record={record} />,
        icon: <DeleteOutlined style={{ color: 'red' }} />,
      });
    }
    return options;
  };

  const columns: ProColumns<Lesson>[] = [
    {
      // title: 'Title',
      hideInTable: true,
      dataIndex: 'q',
      fieldProps: {
        placeholder: 'Search by keywords',
      },
    },
    {
      title: 'SL#',
      hideInSearch: true,
      render: (_text, _record, index) => `${slNo + index}`.padStart(2, '0'),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      hideInSearch: true,

      // sorter: (a: Lesson, b: Lesson) => `${a.title}`.localeCompare(b.title),
      onCell: (record: any): any => {
        return {
          style: { width: '350px' },
        };
      },
      render: (text, record: any) => {
        return (
          <ShortCard
            title={record.title}
            photo={record.thumbnail}
            description={record.edition}
          />
        );
      },

      filterSearch: true,
      onFilter: (value: any, record: any): boolean =>
        record.title?.startsWith(value),
    },
    {
      // title: 'Bundle',
      hideInTable: true,
      dataIndex: 'bundle_id',
      renderFormItem: (item, props, form) => {
        return (
          <BundleSelectionDropdown
            onChange={(value: any) => {
              form.setFieldValue('bundle_id', value);
            }}
            onClear={() => {
              form.setFieldValue('bundle_id', '');
            }}
          />
        );
      },
    },
    {
      title: 'Description',
      hideInSearch: true,
      dataIndex: 'description',
    },
    {
      title: 'Flashcards',
      hideInSearch: true,

      render: (text, record: any) => {
        return (
          <span title={'Total Flashcards'}>{record.stats?.flashcardCount}</span>
        );
      },
    },
    {
      title: 'Order',
      hideInSearch: true,
      dataIndex: 'priority',
    },
    {
      title: 'Actions',
      valueType: 'option',
      hideInSetting: true,
      key: 'option',
      render: (_text, record, _index) => (
        <Space size="middle">
          <Button
            onClick={() => {
              // eslint-disable-next-line no-console
              setEditingLesson(record);
              setIsModalVisible(true);
              // reloadData();
            }}
          >
            Edit
          </Button>
          {record.stats.flashcardCount === 0 && (
            <Dropdown menu={{ items: getDropdownOptions(record) }}>
              <span>...</span>
            </Dropdown>
          )}
        </Space>
      ),
    },
  ];
  const updateLesson = (lesson: Lesson) => {
    // setLessons(items);
    // eslint-disable-next-line no-console
    setIsModalVisible(false);
    reloadData();
  };

  const LessonEditModal = () => (
    <Modal
      title={editingLesson?.title}
      open={isModalVisible}
      onOk={() => setIsModalVisible(false)}
      onCancel={() => setIsModalVisible(false)}
      width="60%"
      footer={false}
    >
      <LessonForm lesson={editingLesson} onUpdate={updateLesson} />
    </Modal>
  );
  return (
    <>
      {/* <ProTable columns={columns} dataSource={userList} /> */}
      <ProTable
        columns={columns}
        rowKey={'id'}
        formRef={formRef}
        request={async (params, sorter, filter) => {
          // eslint-disable-next-line no-console
          console.log('filter params-->', params);
          const response = await getLessons({
            page: params.current,
            per_page: params.pageSize,
            ...params,
          });

          slNo = ((params.current || 0) - 1) * (params.pageSize || 0) + 1;

          return {
            data: response.data,
            // Please return true for success.
            // otherwise the table will stop parsing the data, even if there is data
            success: true,
            // not passed will use the length of the data, if it is paged you must pass
            total: response.meta?.total,
          };
        }}
      />
      <LessonEditModal />
    </>
  );
};

export default LessonList;
