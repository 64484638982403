/* eslint-disable no-console */
import lodash from 'lodash';
import moment from 'moment';
import store from '../store';
import { getTasks, TaskObj, updateTasks } from '../store/ducks/tasks';

/**
 * compares and checks whether two tree nodes are equal
 * @param treeNodeA - the first tree node
 * @param treeNodeB - the second tree node
 * @returns {boolean} - true if tree nodes are equal; otherwise false
 */
export const customTreeNodeComparator = (treeNodeA: any, treeNodeB: any) => {
  return (
    treeNodeA.id === treeNodeB.id &&
    treeNodeA.parent === treeNodeB.parent &&
    treeNodeA.order === treeNodeB.order &&
    treeNodeA.expanded === treeNodeB.expanded
  );
};

/**
 * flatDataBasedOnHeightIterator recursively iterates through data and adds rows that satisfy the height condition
 * @param {any} data - the rows of data to be filterd
 * @param {any} filteredRows - the rows of data after filter
 * @param {string} parent - the current parent id of iterator
 * @param {number} height - the current height of iterator
 * @param {number} maxheight - the maximum height that is allowed
 */
const flatDataBasedOnHeightIterator = (
  data: any,
  filteredRows: any,
  parent: string,
  height: number,
  maxheight: number
) => {
  const tmpContainer = lodash.filter(data, { parent });
  if (tmpContainer.length === 0 || height > maxheight) {
    return;
  }
  tmpContainer.forEach((node: any) => {
    flatDataBasedOnHeightIterator(
      data,
      filteredRows,
      node.id,
      height + 1,
      maxheight
    );
    filteredRows.push(node);
  });
  return;
};

/**
 * getFlatDataBasedOnHeight filters the input data based on their height. Height starts from 0.
 * @param {any} data - the input data
 * @param {number} maxheight - the maximum height that is allowed. 0 represents only root rows.
 * @returns {any} - filtered rows
 */
export const getFlatDataBasedOnHeight = (data: any, maxheight: number): any => {
  const filteredRows: any = [];
  flatDataBasedOnHeightIterator(data, filteredRows, '', 0, maxheight);
  return filteredRows;
};

/**
 * recursively iterates the dataset to find the maximum height
 * @param data - the input dataset
 * @param currentParent - the current parent of the iterator
 * @param {number} currentHeight - the current height of the iterator
 * @returns {number} - returns the max height based on current parent
 */
const maxHeightIterator = (
  data: any,
  currentParent: string,
  currentHeight: number
): number => {
  const tmpContainer = lodash.filter(data, { parent: currentParent });
  if (tmpContainer.length === 0) {
    return currentHeight;
  }
  let maxHeight = currentHeight;
  tmpContainer.forEach((iterObj: any) => {
    const childHeight = maxHeightIterator(data, iterObj.id, currentHeight + 1);
    if (maxHeight < childHeight) {
      maxHeight = childHeight;
    }
  });
  return maxHeight;
};

/**
 * returns the max height or depth of root node
 * @param {any} data - the input data set
 * @param {string} rootId - the root or start node id
 * @returns {number} - returns the max height or depth of the root node
 */
export const getMaxHeight = (data: any, rootId: string): number => {
  return maxHeightIterator(data, rootId, 0);
};

/**
 * iterates the tree flat data to look and push favourite items
 * @param {FavouriteIteratorType[]} data - the flat data
 * @param {number} currentParent - the current iterator parent
 * @param {FavouriteIteratorType[]} favourties - the favourites container to push into
 */
const favouriteIterator = <FavouriteIteratorType>(
  data: FavouriteIteratorType[],
  currentParent: string,
  favourties: FavouriteIteratorType[]
) => {
  const tmpContainer = lodash.orderBy(
    lodash.filter(data, { parent: currentParent }),
    ['order'],
    ['asc']
  );
  if (tmpContainer.length === 0) {
    return;
  }
  tmpContainer.forEach((iterObj: any) => {
    if (iterObj.isFavourite) {
      favourties.push(iterObj);
    }
    favouriteIterator(data, iterObj.id, favourties);
  });
  return;
};

/**
 * returns the list of favourites with the tree order
 * @param {FavouriteType[]} data - the flat rows of data
 * @returns {FavouriteType[]} - the list of favourite items
 */
export const getFavourites = <FavouriteType>(
  data: FavouriteType[]
): FavouriteType[] => {
  const favourites: FavouriteType[] = [];
  favouriteIterator<FavouriteType>(data, '', favourites);
  return favourites;
};

/**
 * iterator for the transfer sub tasks list
 * @param {TaskObj[]} transferTasks - the tasks that will be transfered
 * @param {TaskObj[]} currentTasks - the current tasks at the store
 * @param {string} parentId - the parent task id
 * @param {string} projectId - the project id where the tasks will be transfered
 */
const transferSubTasksListIterator = (
  transferTasks: TaskObj[],
  currentTasks: TaskObj[],
  parentId: string,
  projectId: string
) => {
  const immediateTasks = lodash.filter(currentTasks, { parent: parentId });
  immediateTasks.forEach((iterTask) => {
    transferSubTasksListIterator(
      transferTasks,
      currentTasks,
      iterTask.id,
      projectId
    );
    //TODO: need to change sync status when this becomes available
    transferTasks.push({ ...iterTask, projectId, sectionId: '' });
  });
};

/**
 * transfers the sub tasks to the requested project id
 * @param {string} parentId - the requested parent id
 * @param {string} projectId - the requested project id
 */
export const transferSubTaskListByParent = (
  parentId: string,
  projectId: string
) => {
  const currentTasks = getTasks((store as any).getState());
  const transferTasks: TaskObj[] = [];
  transferSubTasksListIterator(
    transferTasks,
    currentTasks,
    parentId,
    projectId
  );
  (store as any).dispatch(updateTasks(transferTasks));
};

/**
 * complete sub tasks list iterator
 * @param {TaskObj[]} completeTasks - the tasks that will be completed
 * @param {TaskObj[]} currentTasks - the current tasks at the store
 * @param {string} parentId - the parent task id
 */
const completeSubTasksListIterator = (
  completeTasks: TaskObj[],
  currentTasks: TaskObj[],
  parentId: string
) => {
  const immediateTasks = lodash.filter(currentTasks, { parent: parentId });
  immediateTasks.forEach((iterTask) => {
    completeSubTasksListIterator(completeTasks, currentTasks, iterTask.id);
    //TODO: need to change sync status when this becomes available
    completeTasks.push({ ...iterTask, completed: true });
  });
};

/**
 * completes the sub tasks given the parent id
 * @param {string} parentId - the requested parent id
 */
export const completeSubTaskListByParent = (parentId: string) => {
  const currentTasks = getTasks((store as any).getState());
  const completeTasks: TaskObj[] = [];
  completeSubTasksListIterator(completeTasks, currentTasks, parentId);
  (store as any).dispatch(updateTasks(completeTasks));
};

export const getProviderId = () => {
  // @TODO: need to get the provider id from the store
  // orgznizaion_id for         "email": "smith@heal.com",
  return '7';
  //  return getUserId(store.getState());
};

export const getCurreny = () => {
  return 'BDT';
};

export const getYMD = (date1: moment.Moment, date2: moment.Moment) => {
  const a = moment(date1);
  const b = moment(date2);
  const years = a.diff(b, 'year');
  b.add(years, 'years');

  const noOfDaysInb = b.daysInMonth();
  const noOfDaysIna = a.daysInMonth();
  let months = 0;
  if (noOfDaysInb > noOfDaysIna) {
    months = b.diff(a, 'months');
    a.add(months, 'months');
  } else {
    months = a.diff(b, 'months');
    b.add(months, 'months');
  }
  const days = a.diff(b, 'days');
  return {
    years: Math.abs(years),
    months: Math.abs(months),
    days: Math.abs(days),
  };
};

export const getEnumValues = (obj: any) => Object.values(obj);

export const dateFormat = 'YYYY-MM-DD';

export const strLimit = (str: string, limit: number) =>
  str && str.substring(0, limit) + (str.length > limit ? '...' : '');
