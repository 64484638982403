import { ActionType, ProColumns, ProTable } from '@ant-design/pro-components';
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import BundleSelectionDropdown from '../input/BundleSelectionDropdown';
import { Button, Tooltip } from 'antd';
import styles from './Styles.module.scss';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { getPoints } from '../../api/points/request';
import { Point } from '../../interfaces/point';
import { PlusOutlined } from '@ant-design/icons';
import { UserBundle } from '../../interfaces/bundle';
import { getUserBundles } from '../../api/users/request';

interface Props {
  userId: number;
  onAdd?: any;
}

const Bundles = (props: Props) => {
  let slNo = 1;
  const [searchParams, setSearchParams] = useSearchParams();

  const columns: ProColumns<UserBundle>[] = [
    {
      title: 'SL#',
      hideInSearch: true,
      render: (_text, _record, index) => `${slNo + index}`.padStart(2, '0'),
    },
    {
      title: 'Title',
      hideInSearch: true,
      dataIndex: 'title',
    },
    {
      title: 'Particulars',
      hideInSearch: true,
      dataIndex: 'particulars',
    },
    {
      title: 'Total',
      hideInSearch: true,
      render: (text, record) => {
        return (
          <div className={styles.textRight}>{record.stats.flashcardCount}</div>
        );
      },
    },
    {
      title: 'Read',
      hideInSearch: true,
      render: (text, record) => {
        return (
          <div className={styles.textRight}>{record.stats?.readCount}</div>
        );
      },
    },
    {
      title: 'Strength',
      hideInSearch: true,
      render: (text, record) => {
        return <div className={styles.textRight}>{record.stats?.strength}</div>;
      },
    },

    {
      title: 'Weakness',
      hideInSearch: true,
      render: (text, record) => {
        return <div className={styles.textRight}>{record.stats?.weakness}</div>;
      },
    },
    {
      title: 'Due Date',
      hideInSearch: true,
      render: (text, record) => {
        const date = moment().add(record.stats.dueDays, 'day');
        const dateTime = date.format('DD-MMM-yyyy, hh:mm A');
        return (
          <Tooltip title={dateTime}>
            {date.format('DD-MMM-yyyy')} {record.stats.dueDays}
          </Tooltip>
        );
      },
      valueType: 'dateRange',
      search: {
        transform: (value) => {
          // eslint-disable-next-line no-console
          console.log('dates 2', value);
          return {
            start_date: value[0],
            end_date: value[1],
          };
        },
      },
    },
    {
      title: 'Bundles',
      dataIndex: 'bundle_ids',
      hideInTable: true,
      hideInSearch: true,
      renderFormItem(schema, config, form, action) {
        return (
          <BundleSelectionDropdown
            mode="multiple"
            onChange={(value: any) => {
              form.setFieldValue('bundle_ids', value);
            }}
            onClear={() => {
              form.setFieldValue('bundle_ids', '');
            }}
          />
        );
      },
      fieldProps: {
        placeholder: 'Already enrolled.',
      },
    },
  ];
  const actionRef = useRef<ActionType>();

  return (
    <>
      <ProTable
        columns={columns}
        rowKey={'id'}
        actionRef={actionRef}
        pagination={{
          pageSize: 15,
        }}
        search={false}
        request={async (params, sorter, filter) => {
          params = {
            page: params.current,
            per_page: params.pageSize,
            ...params,
          };

          if (props.userId) {
            params['id'] = [props.userId];
          }
          const response = await getUserBundles(params);
          // eslint-disable-next-line no-console
          slNo = ((params.current || 0) - 1) * (params.pageSize || 0) + 1;

          return {
            data: response.data,
            // Please return true for success.
            // otherwise the table will stop parsing the data, even if there is data
            success: true,
            // not passed will use the length of the data, if it is paged you must pass
            total: response.meta?.total,
            pageSize: response.meta?.perPage,
          };
        }}
      />
    </>
  );
};

export default Bundles;
