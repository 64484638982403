import { ProColumns, ProTable } from '@ant-design/pro-components';
import React from 'react';
import moment from 'moment';
import BundleSelectionDropdown from '../input/BundleSelectionDropdown';
import { Tooltip } from 'antd';
import styles from './UserList.module.scss';
import { useSearchParams } from 'react-router-dom';
import { Quiz } from '../../interfaces/quiz';
import { getQuizzes } from '../../api/quizzes/request';
interface Props {
  userId?: number;
}
const List = (props: Props) => {
  let slNo = 1;
  const [searchParams, setSearchParams] = useSearchParams();

  const columns: ProColumns<Quiz>[] = [
    {
      title: 'SL#',
      hideInSearch: true,
      render: (_text, _record, index) => `${slNo + index}`.padStart(2, '0'),
    },
    {
      title: 'Category',
      hideInSearch: true,
      dataIndex: 'category',
    },
    {
      title: 'Points',
      hideInSearch: true,
      render: (text, record) => {
        return (
          <div className={styles.textRight}>
            {record.score}/{record.totalMarks}
          </div>
        );
      },
    },
    {
      title: 'Percent(%)',
      hideInSearch: true,
      render: (text, record) => {
        return (
          <div className={styles.textRight}>
            {((record.score / (record.totalMarks || 1)) * 100).toFixed(2)}
          </div>
        );
      },
    },
    {
      title: 'Time',
      render: (text, record) => {
        const date = moment(record.time).format('DD-MMM-yyyy');
        const dateTime = moment(record.time).format('DD-MMM-yyyy, hh:mm A');
        return <Tooltip title={dateTime}>{date}</Tooltip>;
      },
      valueType: 'dateRange',
      search: {
        transform: (value) => {
          return {
            start_date: value[0],
            end_date: value[1],
          };
        },
      },
    },
    {
      title: 'Bundles',
      dataIndex: 'bundle_ids',
      hideInTable: true,
      renderFormItem(schema, config, form, action) {
        return (
          <BundleSelectionDropdown
            mode="multiple"
            onChange={(value: any) => {
              form.setFieldValue('bundle_ids', value);
            }}
            onClear={() => {
              form.setFieldValue('bundle_ids', '');
            }}
          />
        );
      },
      fieldProps: {
        placeholder: 'Already enrolled.',
      },
    },
  ];

  return (
    <>
      <ProTable
        columns={columns}
        rowKey={'id'}
        pagination={{
          pageSize: 15,
        }}
        search={false}
        request={async (params, sorter, filter) => {
          params = {
            page: params.current,
            per_page: params.pageSize,
            ...params,
          };

          if (props.userId) {
            params['user_ids'] = [props.userId];
          }

          const response = await getQuizzes(params);
          slNo = ((params.current || 0) - 1) * (params.pageSize || 0) + 1;

          return {
            data: response.data,
            // Please return true for success.
            // otherwise the table will stop parsing the data, even if there is data
            success: true,
            // not passed will use the length of the data, if it is paged you must pass
            total: response.meta?.total,
          };
        }}
      />
    </>
  );
};

export default List;
