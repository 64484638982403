import { DatePicker, Input, Modal } from 'antd';
import moment from 'moment';
import React from 'react';
import { SERVER_USER_PROFILE_ENDPOINT } from '../../configs/endpoints';
import { axioService, POST } from '../../services/axioService';
import './DOBModal.scss';

interface DOBModalProps {
  selected: string;
  saveHandler: (requested: string) => void;
  visible: boolean;
  cancelHandler: () => void;
}

const DOBModal: React.FC<DOBModalProps> = (props: DOBModalProps) => {
  const { visible, cancelHandler, selected, saveHandler } = props;

  /** manages the input text */
  const [text, setText] = React.useState<string>('');

  /** resets the text on modal visibility change */
  React.useEffect(() => setText(''), [visible]);

  /**
   * manages the onchange request
   * @param {any} date - the moment date
   * @param {any} dateString - the string date
   */
  const onChangeHandler = (date: any, dateString: string): any => {
    setText(dateString);
  };

  /** manages the save request */
  const confirmHandler = async () => {
    if (text === '') return;
    try {
      /** create the form data request */
      const request = new FormData();
      request.append('date_of_birth', text);
      await axioService(POST, SERVER_USER_PROFILE_ENDPOINT, request, true);
      saveHandler(text);
      setText('');
    } catch (exception) {
      console.error(exception);
    }
  };

  return (
    <Modal
      title="Change Date of Birth"
      visible={visible}
      okText="save"
      onOk={confirmHandler}
      onCancel={cancelHandler}
      okButtonProps={{ disabled: text === '' }}
    >
      <div className="DOBModal-field-container">
        <div className="DOBModal-field-label">Existing Birthday</div>
        <div className="DOBModal-field-input">
          <Input
            disabled={true}
            value={selected}
            placeholder="Not provided yet"
          />
        </div>
      </div>
      <div className="DOBModal-field-container">
        <div className="DOBModal-field-label">New Birthday</div>
        <div className="DOBModal-field-input">
          <DatePicker
            // defaultValue={moment().format('yyyy-mm-dd')}
            onChange={onChangeHandler}
            allowClear={false}
            disabledDate={(d: any) => !d || d.isAfter(moment())}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DOBModal;
