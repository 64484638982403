/* eslint-disable no-console */
/* eslint-disable jsx-a11y/anchor-is-valid */
import type { ActionType, ProColumns } from '@ant-design/pro-components';
import { LightFilter, ProTable } from '@ant-design/pro-components';
import {
  Button,
  Checkbox,
  Dropdown,
  Input,
  message,
  Modal,
  Pagination,
  Popconfirm,
  Space,
} from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  deleteBundle,
  exportBundle,
  getBundles,
  uploadFile,
} from '../../api/bundles/request';
import { Bundle } from '../../interfaces/bundle';
import ShortCard from '../shortCard/ShortCard';
import BundleForm from '../bundleForm/BundleForm';
import {
  DeleteOutlined,
  DownOutlined,
  ExportOutlined,
  ImportOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import Styles from './BundleList.module.scss';
import { LayoutContext } from '../layout/LayoutContext';
import CreateBundle from './CreateBundle';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

const BundleList = () => {
  const actionRef = useRef<ActionType>();
  const [bundles, setBundles] = useState<Array<Bundle>>([]);
  const [meta, setMeta] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [editingBundle, setEditingBundle] = useState<any>(null);
  const [filterParams, setFilterParams] = useState({
    page: 1,
    per_page: 15,
    q: '',
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { setPageMenus } = useContext(LayoutContext);

  useEffect(() => {
    fetchAppointments(filterParams);
    setPageMenus([
      {
        component: CreateBundle,
      },
    ]);
  }, [filterParams.page, filterParams.q]);

  const navigate = useNavigate();

  const getDropdownOptions = (record: Bundle): MenuProps['items'] => {
    const options = [
      {
        key: record.id + '1',
        label: <BundleImport record={record} />,
        icon: <ImportOutlined />,
      },
      {
        key: record.id + '1',
        label: <BundleExport record={record} />,
        icon: <ExportOutlined />,
      },
    ];

    if (!record.stats?.userCount) {
      options.push({
        key: record.id + '2',
        label: <DeleteBundle record={record} />,
        icon: <DeleteOutlined style={{ color: 'red' }} />,
      });
    }
    return options;
  };

  const fetchAppointments = async (requestParam: any) => {
    setLoading(true);
    try {
      const response = await getBundles(requestParam);
      setBundles(response.data);
      setMeta(response.meta);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const columns: ProColumns<Bundle>[] = [
    {
      title: 'SL#',
      filterSearch: false,
      render: (_text, _record, index) =>
        `${
          meta.current_page * meta.per_page - meta.per_page + index + 1
        }`.padStart(2, '0'),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      sorter: (a, b) => `${a.title}`.localeCompare(b.title),
      onCell: (record: any): any => {
        return {
          style: { width: '350px' },
        };
      },
      render: (_text, record, _index) => {
        return (
          <ShortCard
            title={record.title}
            photo={record.thumbnail}
            description={record.edition}
            url={record.url}
          />
        );
      },
      filterSearch: true,
      onFilter: (value: any, record): boolean =>
        record.title?.startsWith(value),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render: (_text, record, _index) => {
        const description: string = record.description;
        const length = 200;
        return (
          <div title={description}>
            {description.substring(0, length) +
              (description.length > length ? '...' : '')}
          </div>
        );
      },
    },
    {
      title: 'Flashcards',
      dataIndex: 'flashcard_count',
      render: (_text, record, _index) => {
        return <div>{record?.stats?.flashcardCount}</div>;
      },
    },
    {
      title: 'Users',
      dataIndex: 'user_count',
      render: (_text, record, _index) => {
        return <div>{record?.stats?.userCount}</div>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'Actions',
      valueType: 'option',
      hideInSetting: true,
      key: 'option',
      render: (_text, record, _index) => (
        <Space size="middle">
          <a
            onClick={() => {
              setEditingBundle(record);
              setIsModalVisible(true);
            }}
          >
            Edit
          </a>
          <Dropdown menu={{ items: getDropdownOptions(record) }}>
            <a>...</a>
          </Dropdown>
        </Space>
      ),
    },
  ];
  const BundleExport = (props: { record: Bundle }) => {
    return (
      <>
        <a onClick={() => exportBundle({}, props.record.id)}>Export</a>
      </>
    );
  };

  const BundleImport = (props: { record: Bundle }) => {
    const record = props.record;
    const [fileUploadModal, setFileUploadModal] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [shouldMatchRefId, setShouldMatchRefId] = useState(false);
    const [file, setFile] = useState<any>('');
    const inputRef = useRef<any>();

    const onUpload = async () => {
      const formData = new FormData();
      formData.append('file', file);
      let matchRefId = 'no';

      if (shouldMatchRefId) {
        matchRefId = 'yes';
      }

      formData.append('match_ref_id', matchRefId);

      try {
        setUploading(true);
        const res = await uploadFile(formData, record.id);
        setFileUploadModal(false);
        if (inputRef) {
          inputRef.current.value = '';
        }
        setUploading(false);
      } catch (e) {
        message.error('an error occured, upload again');
        setUploading(false);
      }
    };
    const onChange = (e: CheckboxChangeEvent) => {
      const isChecked = e.target.checked;
      setShouldMatchRefId(isChecked);
    };

    return (
      <div>
        <a onClick={() => setFileUploadModal(true)}>Import</a>

        <Modal
          title={`Upload to "${record.title}"`}
          open={fileUploadModal}
          onOk={() => setFileUploadModal(false)}
          onCancel={() => setFileUploadModal(false)}
          width="60%"
          footer={
            <div>
              <Checkbox onChange={onChange}>Match Ref ID</Checkbox>
              <Button loading={uploading} onClick={onUpload} type="primary">
                Upload
              </Button>
            </div>
          }
        >
          <div
            style={{
              width: '100%',
              height: '300px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <input
              type="file"
              onChange={(e: any) => {
                setFile(e.target.files[0]);
              }}
              ref={inputRef}
            />
          </div>
        </Modal>
      </div>
    );
  };

  const updateBundle = (bundle: Bundle) => {
    const items = bundles.map((item: Bundle) => {
      if (item?.id === bundle.id) {
        return { ...item, ...bundle };
      }
      return item;
    });

    setBundles(items);
    setIsModalVisible(false);
  };
  const onPaginateChange = (value: number) => {
    const params = { ...filterParams, page: value };
    setFilterParams(params);
  };

  const onFilter = (filter: any) => {
    setFilterParams({ ...filterParams, ...filter });
  };

  const DeleteBundle = (props: { record: Bundle }) => {
    const [deleteText, setDeleteText] = useState('');
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const record = props.record;
    const bundleId = props?.record.id;

    const onConfirm = () => {
      deleteBundle({ id: bundleId });
      const bundleList = bundles.filter(
        (bundle: Bundle) => bundle.id !== bundleId
      );
      setBundles(bundleList);
      setIsDeleteModalOpen(false);
    };
    return (
      <div>
        <span
          style={{ cursor: 'pointer', color: 'red' }}
          onClick={() => setIsDeleteModalOpen(true)}
        >
          Delete
        </span>
        <Modal
          title={
            <div>
              Delete <em>{record?.title}</em> ?
            </div>
          }
          open={isDeleteModalOpen}
          onOk={() => setIsDeleteModalOpen(false)}
          onCancel={() => setIsDeleteModalOpen(false)}
          width="60%"
          footer={
            <Button
              onClick={onConfirm}
              type="dashed"
              disabled={deleteText === 'delete' ? false : true}
            >
              Confirm
            </Button>
          }
        >
          <Input
            placeholder="Type 'delete'"
            onChange={(ev) => setDeleteText(ev.target.value)}
          />
        </Modal>
      </div>
    );
  };

  const BundleEditModal = () => (
    <Modal
      title={editingBundle?.title}
      open={isModalVisible}
      onOk={() => setIsModalVisible(false)}
      onCancel={() => setIsModalVisible(false)}
      width="60%"
      footer={false}
    >
      <BundleForm bundle={editingBundle} onUpdate={updateBundle} />
    </Modal>
  );

  return (
    <>
      {/* <Filter onFilter={onFilter} /> */}

      <ProTable<any>
        dataSource={bundles}
        columns={columns}
        actionRef={actionRef}
        search={false}
        toolbar={{
          search: {
            onSearch: (value: string) => onFilter({ q: value }),
          },
          filter: (
            <LightFilter
              placement="topRight"
              placeholder="Bundle Name"
              title="Search Bundle"
            ></LightFilter>
          ),
        }}
        pagination={false}
        rowKey="id"
        cardBordered
        loading={loading}
        options={{
          density: false,
          fullScreen: false,
          reload: false,
          setting: false,
        }}
      />
      <div className="list-pagination">
        <Pagination
          onChange={onPaginateChange}
          defaultCurrent={1}
          total={meta?.total || 0}
          pageSize={meta?.per_page || 15}
          showSizeChanger={false}
        />
      </div>
      <BundleEditModal />
      {/* <BundleUploadModal /> */}
    </>
  );
};

export default BundleList;
