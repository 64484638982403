/* eslint-disable no-console */
import { Button, Col, Form, Input, Row, Select } from 'antd';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { updateFlashcard } from '../../api/flashcards/request';
import styles from './FlashcardForm.module.scss';

interface Props {
  flashcard?: any;
  patientName?: string;
  submitBtnLabel?: string;
  onPatientCreate?: any;
  shortForm?: boolean;
  showTitle?: boolean;
  onUpdate: any;
}

const FlashcardFormContent = (props: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const flashcard = props.flashcard || {};

  useEffect(() => {
    form.setFieldsValue({
      ...flashcard,
      option_1: flashcard?.options[0] || '',
      option_2: flashcard?.options[1] || '',
      option_3: flashcard?.options[2] || '',
      option_4: flashcard?.options[3] || '',
      notes: flashcard?.notes?.content || '',
    });
  }, [flashcard]);

  const onFinish = async (request: any) => {
    console.log('Form data', request);
    const payload = {
      id: flashcard.id,
      title: request.title,
      notes: {
        content: request.notes,
        media: flashcard.note?.media || [],
      },
      options: [
        request.option_1 || null,
        request.option_2 || null,
        request.option_3 || null,
        request.option_4 || null,
      ],
      answer: request.answer,
    };
    const resp = await updateFlashcard(payload);
    props.onUpdate({ ...flashcard, ...payload });
  };

  const onReset = () => {
    form.resetFields();
  };
  return (
    <div
      className={clsx(
        styles.FormWrapper,
        'bg-white form__content',
        props.shortForm && styles.ShortForm
      )}
    >
      {!props.showTitle ? null : <h2>{flashcard.bundle_title}</h2>}
      <Form
        initialValues={flashcard}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        form={form}
        name="control-hooks"
        onFinish={onFinish}
      >
        <Row gutter={15}>
          <Col
            lg={props.shortForm ? 24 : 16}
            md={props.shortForm ? 24 : 16}
            sm={24}
            xs={24}
          >
            <Form.Item name="title" label="Title" rules={[{ required: true }]}>
              <Input.TextArea />
            </Form.Item>
          </Col>
          <Col lg={12} md={12} sm={12} xs={24}>
            <Form.Item name="option_1" label="Option 1">
              <Input />
            </Form.Item>
          </Col>
          <Col lg={12} md={12} sm={12} xs={24}>
            <Form.Item name="option_2" label="Option 2">
              <Input />
            </Form.Item>
          </Col>
          <Col lg={12} md={12} sm={12} xs={24}>
            <Form.Item name="option_3" label="Option 3">
              <Input />
            </Form.Item>
          </Col>
          <Col lg={12} md={12} sm={12} xs={24}>
            <Form.Item name="option_4" label="Option 4">
              <Input />
            </Form.Item>
          </Col>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Form.Item name="answer" label="Answer">
              <Select
                allowClear
                placeholder="All statuses"
                style={{ width: '100%' }}
              >
                <Select.Option key={0} value={1}>
                  1
                </Select.Option>
                <Select.Option key={1} value={2}>
                  2
                </Select.Option>
                <Select.Option key={2} value={3}>
                  3
                </Select.Option>
                <Select.Option key={3} value={4}>
                  4
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col
            lg={props.shortForm ? 24 : 16}
            md={props.shortForm ? 24 : 16}
            sm={24}
            xs={24}
          >
            <Form.Item name="notes" label="Notes" rules={[{ required: false }]}>
              <Input.TextArea />
            </Form.Item>
          </Col>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Form.Item>
              <Button loading={loading} type="primary" htmlType="submit">
                {props.submitBtnLabel || 'Update'}
              </Button>
              <Button
                style={{ marginLeft: '20px' }}
                htmlType="button"
                onClick={onReset}
              >
                Reset
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default FlashcardFormContent;
