/* eslint-disable no-console */
import * as endpoints from '../../configs/endpoints';
import { axioService, GET, POST } from '../../services/axioService';
import { mapLessonsServerToClient } from './mapper';

/**
 * Get all patients
 * @param page the number of page
 * @returns {Promise<CreatePatientResponse[]>} a promise of array of patients with pagination
 */
export const getLessons = async (request: any): Promise<any> => {
  const response = await axioService(
    GET,
    endpoints.SERVER_LESSONS_ENDPOINT,
    request,
    true
  );

  const data = mapLessonsServerToClient(response.data.data);

  return {
    data,
    meta: response.data.meta,
  };
};

export const updateLesson = async (
  request: any
): Promise<{
  data: any;
}> => {
  const response = await axioService(
    POST,
    endpoints.SERVER_LESSON_UPDATE_ENDPOINT.replace(':id', request.id),
    request,
    true
  );
  return {
    data: response,
  };
};

export const deleteLesson = async (
  request: any
): Promise<{
  data: any;
}> => {
  const response = await axioService(
    POST,
    endpoints.SERVER_LESSON_DELETE_ENDPOINT.replace(':id', request.id),
    request,
    true
  );
  return {
    data: response,
  };
};

export const createLesson = async (
  request: any
): Promise<{
  data: any;
}> => {
  const response = await axioService(
    POST,
    endpoints.SERVER_LESSON_CREATE_ENDPOINT,
    request,
    true
  );
  return {
    data: response,
  };
};
