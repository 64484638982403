import React from 'react';

const LogoFullImg = () => (
  <svg
    width="200"
    height="50"
    viewBox="0 0 246 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ filter: 'brightness(.8)' }}
  >
    <path
      d="M103.107 28.4631C103.107 20.737 91.7791 22.5452 91.7791 18.5343C91.7791 17.0877 92.7911 16.3973 94.1296 16.4302C95.6313 16.463 96.676 17.3507 96.7739 18.8302H102.813C102.585 14.4247 99.2223 11.926 94.2275 11.926C89.4286 11.926 85.8702 14.3589 85.8702 18.7315C85.8049 26.9179 97.2636 24.6165 97.2636 28.8905C97.2636 30.2384 96.2189 31.0603 94.5866 31.0603C93.0196 31.0603 91.9097 30.2055 91.7465 28.4302H85.8049C85.9682 33.0987 89.8204 35.5645 94.7499 35.5645C100.104 35.5645 103.107 32.3425 103.107 28.4631Z"
      fill="white"
    ></path>
    <path
      d="M105.302 16.7589H111.374V35.3343H116.957V16.7589H123.029V12.2548H105.302V16.7589Z"
      fill="white"
    ></path>
    <path
      d="M125.732 26.0302C125.732 32.4083 129.878 35.5645 135.2 35.5645C140.488 35.5645 144.928 32.4083 144.928 26.0302V12.2548H139.346V26.0631C139.346 28.7918 137.942 30.3699 135.298 30.3699C132.653 30.3699 131.315 28.7918 131.315 26.0631V12.2548H125.732V26.0302Z"
      fill="white"
    ></path>
    <path
      d="M157.434 12.2548H148.848V35.3343H157.434C164.747 35.3343 169.448 30.6329 169.448 23.7946C169.448 16.9233 164.747 12.2548 157.434 12.2548ZM154.431 30.4686V17.0548H157.075C161.286 17.0548 163.767 19.5535 163.767 23.7946C163.767 28.0357 161.286 30.4686 157.075 30.4686H154.431Z"
      fill="white"
    ></path>
    <path
      d="M172.567 35.3343H178.15V16.9891H172.567V35.3343ZM175.375 15.0822C177.334 15.0822 178.672 13.7343 178.672 12.0575C178.672 10.3479 177.334 9 175.375 9C173.384 9 172.045 10.3479 172.045 12.0575C172.045 13.7343 173.384 15.0822 175.375 15.0822Z"
      fill="white"
    ></path>
    <path
      d="M182.195 12.2548V35.3343H195.09V30.9946H187.778V12.2548H182.195Z"
      fill="white"
    ></path>
    <path
      d="M220.311 23.7288C220.311 16.7918 215.121 11.926 208.591 11.926C202.095 11.926 196.806 16.7918 196.806 23.7288C196.806 30.6658 202.095 35.5645 208.591 35.5645C215.088 35.5645 220.311 30.6658 220.311 23.7288ZM202.519 23.7288C202.519 19.6192 204.87 17.0219 208.591 17.0219C212.248 17.0219 214.631 19.6192 214.631 23.7288C214.631 27.7727 212.248 30.4357 208.591 30.4357C204.87 30.4357 202.519 27.7727 202.519 23.7288Z"
      fill="white"
    ></path>
    <path
      d="M234.125 11.9918C227.269 11.9918 222.47 16.8576 222.47 23.7617C222.47 30.6658 227.302 35.4987 234.157 35.4987C240.197 35.4987 244.016 31.5206 245.192 27.5425V22.3151H233.047V26.4576H240.132C239.283 29.0548 237.357 30.5343 234.582 30.5343C230.566 30.5343 228.151 28.0357 228.151 23.7617C228.151 19.7507 230.534 17.1863 234.125 17.1863C236.214 17.1863 237.781 18.0082 238.63 19.5535H244.8C243.592 14.8192 239.609 11.9918 234.125 11.9918Z"
      fill="white"
    ></path>
    <path
      d="M85 49.3232H87.6168V57.2089H90.0226V49.3232H92.6395V47.411H85V49.3232Z"
      fill="white"
    ></path>
    <path
      d="M93.8469 57.2089H96.2527V52.9799C96.2527 51.9192 96.8436 51.333 97.7581 51.333C98.6725 51.333 99.2634 51.9192 99.2634 52.9799V57.2089H101.655V52.6589C101.655 50.5653 100.459 49.3371 98.6725 49.3371C97.5892 49.3371 96.731 49.7977 96.2527 50.4676V46.8807H93.8469V57.2089Z"
      fill="white"
    ></path>
    <path
      d="M106.788 51.2213C107.59 51.2213 108.265 51.7098 108.265 52.5612H105.254C105.395 51.6959 106 51.2213 106.788 51.2213ZM110.544 54.6269H107.984C107.787 55.0456 107.407 55.3805 106.732 55.3805C105.958 55.3805 105.325 54.906 105.24 53.8871H110.685C110.713 53.6499 110.727 53.4126 110.727 53.1893C110.727 50.8166 109.109 49.3092 106.816 49.3092C104.481 49.3092 102.849 50.8445 102.849 53.3149C102.849 55.7853 104.509 57.3206 106.816 57.3206C108.772 57.3206 110.165 56.1482 110.544 54.6269Z"
      fill="white"
    ></path>
    <path
      d="M121.028 47.411H114.978V57.2089H121.028V55.2968H117.384V53.1474H120.605V51.3051H117.384V49.3232H121.028V47.411Z"
      fill="white"
    ></path>
    <path
      d="M127.232 57.2089H129.933L127.232 53.3428L129.863 49.4209H127.26L125.994 51.5703L124.489 49.4209H121.787L124.447 53.2311L121.858 57.2089H124.461L125.727 55.0456L127.232 57.2089Z"
      fill="white"
    ></path>
    <path
      d="M130.382 53.3009C130.382 55.7574 131.916 57.3206 133.829 57.3206C134.997 57.3206 135.827 56.7902 136.263 56.1063V57.2089H138.669V49.4209H136.263V50.5235C135.841 49.8396 135.011 49.3092 133.843 49.3092C131.916 49.3092 130.382 50.8445 130.382 53.3009ZM136.263 53.3149C136.263 54.5571 135.461 55.241 134.547 55.241C133.646 55.241 132.83 54.5431 132.83 53.3009C132.83 52.0588 133.646 51.3888 134.547 51.3888C135.461 51.3888 136.263 52.0727 136.263 53.3149Z"
      fill="white"
    ></path>
    <path
      d="M151.228 57.2089H153.62V52.6589C153.62 50.5653 152.368 49.3371 150.426 49.3371C149.287 49.3371 148.302 49.9652 147.781 50.7887C147.247 49.8535 146.29 49.3371 145.108 49.3371C144.081 49.3371 143.279 49.7698 142.815 50.3979V49.4209H140.409V57.2089H142.815V52.9799C142.815 51.9611 143.406 51.4028 144.32 51.4028C145.235 51.4028 145.826 51.9611 145.826 52.9799V57.2089H148.218V52.9799C148.218 51.9611 148.808 51.4028 149.723 51.4028C150.637 51.4028 151.228 51.9611 151.228 52.9799V57.2089Z"
      fill="white"
    ></path>
    <path
      d="M160.69 51.7936V49.3371H161.998C162.955 49.3371 163.363 49.8117 163.363 50.5653C163.363 51.319 162.955 51.7936 161.998 51.7936H160.69ZM165.811 50.5653C165.811 48.7649 164.601 47.411 162.181 47.411H158.284V57.2089H160.69V53.6917H162.181C164.699 53.6917 165.811 52.1844 165.811 50.5653Z"
      fill="white"
    ></path>
    <path
      d="M169.469 53.594C169.469 52.2541 170.13 51.8634 171.27 51.8634H171.931V49.3371C170.876 49.3371 170.018 49.8954 169.469 50.7189V49.4209H167.063V57.2089H169.469V53.594Z"
      fill="white"
    ></path>
    <path
      d="M176.542 51.2213C177.344 51.2213 178.019 51.7098 178.019 52.5612H175.009C175.149 51.6959 175.754 51.2213 176.542 51.2213ZM180.299 54.6269H177.738C177.541 55.0456 177.161 55.3805 176.486 55.3805C175.712 55.3805 175.079 54.906 174.995 53.8871H180.439C180.467 53.6499 180.481 53.4126 180.481 53.1893C180.481 50.8166 178.864 49.3092 176.57 49.3092C174.235 49.3092 172.603 50.8445 172.603 53.3149C172.603 55.7853 174.263 57.3206 176.57 57.3206C178.526 57.3206 179.919 56.1482 180.299 54.6269Z"
      fill="white"
    ></path>
    <path
      d="M184.156 50.5235V49.4209H181.751V60.9215H184.156V56.1203C184.593 56.7762 185.423 57.3206 186.576 57.3206C188.504 57.3206 190.037 55.7574 190.037 53.3009C190.037 50.8445 188.504 49.3092 186.576 49.3092C185.423 49.3092 184.593 49.8396 184.156 50.5235ZM187.589 53.3009C187.589 54.5431 186.773 55.241 185.859 55.241C184.958 55.241 184.142 54.5571 184.142 53.3149C184.142 52.0727 184.958 51.3888 185.859 51.3888C186.773 51.3888 187.589 52.0588 187.589 53.3009Z"
      fill="white"
    ></path>
    <path
      d="M196.265 57.2089H199.177L200.809 50.7607L202.385 57.2089H205.297L207.872 47.411H205.283L203.848 54.5431L202.16 47.411H199.515L197.756 54.5431L196.335 47.411H193.76L196.265 57.2089Z"
      fill="white"
    ></path>
    <path
      d="M209.078 57.2089H211.484V49.4209H209.078V57.2089ZM210.288 48.6114C211.132 48.6114 211.709 48.0391 211.709 47.3273C211.709 46.6015 211.132 46.0293 210.288 46.0293C209.43 46.0293 208.853 46.6015 208.853 47.3273C208.853 48.0391 209.43 48.6114 210.288 48.6114Z"
      fill="white"
    ></path>
    <path
      d="M218.813 49.4209H212.819V51.3888H216.055L212.791 55.3107V57.2089H218.883V55.241H215.478L218.813 51.319V49.4209Z"
      fill="white"
    ></path>
    <path
      d="M219.729 53.3009C219.729 55.7574 221.262 57.3206 223.175 57.3206C224.343 57.3206 225.173 56.7902 225.609 56.1063V57.2089H228.015V49.4209H225.609V50.5235C225.187 49.8396 224.357 49.3092 223.189 49.3092C221.262 49.3092 219.729 50.8445 219.729 53.3009ZM225.609 53.3149C225.609 54.5571 224.807 55.241 223.893 55.241C222.993 55.241 222.177 54.5431 222.177 53.3009C222.177 52.0588 222.993 51.3888 223.893 51.3888C224.807 51.3888 225.609 52.0727 225.609 53.3149Z"
      fill="white"
    ></path>
    <path
      d="M232.161 53.594C232.161 52.2541 232.823 51.8634 233.962 51.8634H234.624V49.3371C233.568 49.3371 232.71 49.8954 232.161 50.7189V49.4209H229.756V57.2089H232.161V53.594Z"
      fill="white"
    ></path>
    <path
      d="M235.295 53.3009C235.295 55.7574 236.829 57.3206 238.756 57.3206C239.91 57.3206 240.74 56.8042 241.176 56.0923V57.2089H243.582V46.8807H241.176V50.4956C240.698 49.7837 239.797 49.3092 238.756 49.3092C236.829 49.3092 235.295 50.8445 235.295 53.3009ZM241.176 53.3149C241.176 54.5571 240.374 55.241 239.46 55.241C238.559 55.241 237.743 54.5431 237.743 53.3009C237.743 52.0588 238.559 51.3888 239.46 51.3888C240.374 51.3888 241.176 52.0727 241.176 53.3149Z"
      fill="white"
    ></path>
    <g clipPath="url(#clip0_1485_2737)">
      <path
        d="M42.2372 63.2878C44.6031 59.7594 45.6332 55.834 49.1537 53.0771C62.9868 42.0949 62.0812 20.7659 47.5689 10.7027C47.1388 10.3964 46.652 10.1468 45.8936 9.69295V22.1727C45.8936 28.4126 45.8936 34.6524 45.8936 40.9037V42.6395V43.7059C45.9962 44.1281 45.9677 44.5716 45.8119 44.9771C45.6562 45.3826 45.3805 45.7308 45.0219 45.975C43.99 46.5552 42.7806 46.733 41.6259 46.4742C40.3582 46.3048 39.1585 45.7996 38.1506 45.0106C37.7884 44.8745 37.2111 44.0349 37.2111 44.0349C36.8602 43.6719 36.9394 42.5941 36.9394 42.5941V41.2554C36.911 40.8474 36.911 40.438 36.9394 40.0301V6.27804H27.9966V8.72861C27.9966 19.62 27.906 30.5114 28.0645 41.4028V43.0366C28.0645 43.0366 28.155 44.1711 27.7928 44.4774C27.7928 44.4774 27.2155 45.3283 26.8532 45.4644C25.8454 46.2534 24.6457 46.7586 23.378 46.928C22.2232 47.1835 21.0149 47.0059 19.982 46.4288C19.6209 46.1868 19.3434 45.8389 19.1873 45.4327C19.0313 45.0265 19.0044 44.5819 19.1103 44.1597C19.0998 43.9935 19.0998 43.8268 19.1103 43.6605C19.1103 33.1208 19.1103 22.5811 19.1103 12.0528V9.77237C18.4651 10.1241 17.9783 10.3283 17.5595 10.6233C14.1872 12.9235 11.3924 15.9755 9.39429 19.5398C7.39618 23.1041 6.24906 27.0838 6.04289 31.1669C5.83673 35.25 6.57711 39.3254 8.20599 43.0737C9.83486 46.8219 12.3079 50.141 15.4313 52.7708C17.254 54.2339 18.7275 56.0867 19.7443 58.1938C20.5367 59.8615 21.4536 61.4725 22.3365 63.1516C10.6316 61.9263 -0.597863 45.5438 0.0247396 30.8404C0.445402 22.5736 3.9983 14.7793 9.95819 9.04859C15.9181 3.31792 23.8355 0.0829851 32.0944 0.00413138C49.3462 -0.268154 63.4962 12.949 64.9226 30.6362C66.0546 44.8745 54.5987 61.5746 42.2372 63.2878Z"
        fill="url(#paint0_linear_1485_2737)"
      ></path>
      <path
        d="M44.8748 51.0578C42.3051 55.7774 39.9619 60.1339 37.562 64.4338C37.4163 64.6511 37.2256 64.8345 37.003 64.9716C36.7803 65.1086 36.5309 65.196 36.2715 65.2279C33.6226 65.296 30.9851 65.2279 28.3475 65.2279C28.0925 65.1764 27.8515 65.0708 27.6406 64.9182C27.4297 64.7655 27.2539 64.5694 27.1249 64.343C24.7477 60.0772 22.4271 55.7887 19.9141 51.1599C20.8536 51.1599 21.5441 51.1599 22.2347 51.1599C24.7364 51.0805 26.6268 49.7077 28.506 48.2555C31.3926 45.9864 33.5887 45.9864 36.43 48.2555C38.7846 50.0821 41.2976 51.4775 44.8748 51.0578Z"
        fill="url(#paint1_linear_1485_2737)"
      ></path>
      <path
        d="M32.3774 74.0319L29.2078 68.1777H35.5017L32.3774 74.0319Z"
        fill="url(#paint2_linear_1485_2737)"
      ></path>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1485_2737"
        x1="-0.5"
        y1="29.4931"
        x2="65.9986"
        y2="30.078"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0090E1"></stop>
        <stop offset="1" stopColor="#1269F9"></stop>
      </linearGradient>
      <linearGradient
        id="paint1_linear_1485_2737"
        x1="19.7221"
        y1="55.2703"
        x2="45.2569"
        y2="55.5621"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0090E1"></stop>
        <stop offset="1" stopColor="#1269F9"></stop>
      </linearGradient>
      <linearGradient
        id="paint2_linear_1485_2737"
        x1="29.1594"
        y1="70.9059"
        x2="35.5984"
        y2="70.9651"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0090E1"></stop>
        <stop offset="1" stopColor="#1269F9"></stop>
      </linearGradient>
      <clipPath id="clip0_1485_2737">
        <rect width="65" height="74" fill="white"></rect>
      </clipPath>
    </defs>
  </svg>
);

export default LogoFullImg;
