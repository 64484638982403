import { Avatar } from 'antd';
import React from 'react';
import styles from './DropdownCard.module.scss';
interface Props {
  item: any;
}
export default function DropdownCard(props: Props) {
  const { photo, title, description } = props.item;
  return (
    <div className={styles.Wrapper + ' dropdown__card'}>
      <div className={styles.Left + ' dropdown__card__left'}>
        {photo || <Avatar shape="square" size={67} />}
      </div>
      <div className={styles.Right}>
        <h4 className={styles.Title + ' dorpdown__card__title'}>{title}</h4>
        <p className={styles.Description + ' dorpdown__card__desc'}>
          {description}
        </p>
      </div>
    </div>
  );
}
