/* eslint-disable no-console */
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
} from 'antd';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { createBundle, updateBundle } from '../../api/bundles/request';
import { getTopics } from '../../api/topics/request';
import { Bundle } from '../../interfaces/bundle';
import styles from './BundleForm.module.scss';
const { Option } = Select;

interface Props {
  bundle?: Bundle;
  submitBtnLabel?: string;
  onUpdate: any;
}

const BundleForm = (props: Props) => {
  const bundle = props.bundle;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [thumbnail, setThumbnail] = useState(bundle?.thumbnail);
  const [topics, setTopics] = useState([]);

  const isEdit = !!props.bundle;

  useEffect(() => {
    form.setFieldsValue({
      title: '',
      price: '',
      edition: '',
      description: '',
      priority: '',
      topic_id: '',
      status: '',
      ...bundle,
    });
  }, [props.bundle]);

  useEffect(() => {
    fetchTopics();
  }, []);

  const fetchTopics = async () => {
    try {
      const response = await getTopics();
      setTopics(response);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  const onFinish = async (request: any) => {
    console.log('Form data', request);
    const bundleId = bundle?.id;
    const payload = {
      title: request.title,
      price: request.price,
      media: { ...bundle?.media, thumbnail },
      edition: request.edition,
      description: request.description,
      priority: request.priority,
      topic_id: request.topic_id,
      status: request.status,
      search_keywords: request.search_keywords,
    };
    if (bundleId) {
      const resp = await updateBundle({ ...payload, id: bundleId });
      props.onUpdate({ ...bundle, ...payload });
    } else {
      const resp = await createBundle(payload);
      if (resp.data.status === 201) {
        message.success('bundle created successfully');
        props.onUpdate(false);
      }
    }
  };

  const onReset = () => {
    form.resetFields();
  };
  return (
    <div className={clsx(styles.FormWrapper, 'bg-white form__content')}>
      <Form
        initialValues={props.bundle}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        form={form}
        name="control-hooks"
        onFinish={onFinish}
      >
        <Row gutter={15}>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Form.Item name="title" label="Title" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item name="price" label="Price" rules={[{ required: true }]}>
              <InputNumber min={0} />
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item
              name="priority"
              label="Priority"
              rules={[{ required: true }]}
            >
              <InputNumber min={0} />
            </Form.Item>
          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              name="edition"
              label="Edition"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              name="topic_id"
              label="Topic"
              rules={[{ required: true }]}
            >
              <Select style={{ width: '100%' }}>
                {topics.map((topic: any) => {
                  topic = { id: 1, title: '', ...topic };
                  return (
                    <Option key={topic.id} value={topic.id}>
                      {topic.title}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              name="status"
              label="Status"
              rules={[{ required: true }]}
            >
              <Select style={{ width: '100%' }} defaultValue={'Draft'}>
                {['Draft', 'Published'].map((status: any) => {
                  return (
                    <Option key={status} value={status}>
                      {status}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              name="description"
              label="Description"
              rules={[{ required: false }]}
            >
              <Input.TextArea rows={3} />
            </Form.Item>
          </Col>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              name="search_keywords"
              label="Search Keywords"
              rules={[{ required: false }]}
              help="Separate keywords by semicolon (;)."
            >
              <Input.TextArea rows={2} />
            </Form.Item>
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Form.Item
              name="thumbnail"
              label="Thumbnail"
              rules={[{ required: true }]}
            >
              <Input.TextArea
                rows={4}
                onChange={(e) => {
                  setThumbnail(e.target.value);
                }}
              />
            </Form.Item>
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Form.Item name="preview" label="Preview">
              {thumbnail && (
                <img src={thumbnail} alt={bundle?.title} width="170px" />
              )}
            </Form.Item>
          </Col>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Form.Item>
              <Button loading={loading} type="primary" htmlType="submit">
                {props.submitBtnLabel || 'Update'}
              </Button>
              <Button
                style={{ marginLeft: '20px' }}
                htmlType="button"
                onClick={onReset}
              >
                Reset
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default BundleForm;
