/* eslint-disable no-console */
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
} from 'antd';
import clsx from 'clsx';
import React, { RefAttributes, useEffect, useRef, useState } from 'react';
import styles from './Styles.module.scss';
import { createCampaign, updateCampaign } from '../../api/campaigns/request';
import { Campaign } from '../../interfaces/campaign';
import { from } from 'seamless-immutable';
import BundleSelectionDropdown from '../input/BundleSelectionDropdown';
import moment from 'moment';

import dayjs from 'dayjs';
interface Props {
  onAdd: any;
  title?: string;
  campaign?: Campaign;
}

const CampaignForm = (props: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [campaignType, setCampaignType] = useState(props.campaign?.type);
  const [thumbnail, setThumbnail] = useState(props.campaign?.thumbnail);

  useEffect(() => {
    form.setFieldsValue({
      ...props.campaign,
    });
  }, [props.campaign]);

  const onFinish = async (request: any) => {
    console.log('Form data', request);
    setLoading(true);
    const payload = {
      ...request,
    };

    let resp = null;

    if (props.campaign) {
      resp = await updateCampaign({ ...request, id: props.campaign.id });
    } else {
      resp = await createCampaign(payload);
    }

    props.onAdd(resp.data);
    setLoading(false);
  };

  const onReset = () => {
    form.resetFields();
  };
  const thumbField: any = useRef();

  return (
    <div className={clsx(styles.FormWrapper, 'bg-white form__content')}>
      {!props.title ? null : <h2>{props.title}</h2>}
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        form={form}
        name="control-hooks"
        onFinish={onFinish}
      >
        <Row gutter={15}>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Form.Item name="name" label="Name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>

          <Col lg={12} md={12} sm={24} xs={24}>
            <Form.Item name="title" label="Title" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>

          <Col lg={12} md={12} sm={24} xs={24}>
            <Form.Item name="type" label="Type" rules={[{ required: true }]}>
              <Radio.Group
                onChange={(e) => setCampaignType(e.target.value)}
                value={props.campaign?.type}
              >
                <Radio value={'notification'}>Notification</Radio>
                <Radio value={'promotion'}>Promotion</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              name="status"
              label="Status"
              rules={[{ required: true }]}
            >
              <Radio.Group>
                <Radio value={'draft'}>Draft</Radio>
                <Radio value={'active'}>Active</Radio>
                <Radio value={'processed'}>Processed</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              name="message"
              label="Message"
              rules={[{ required: true }]}
            >
              <Input.TextArea rows={5} placeholder="Content for the campaign" />
            </Form.Item>
          </Col>

          {campaignType === 'promotion' && (
            <>
              <Form.Item
                name="context_type"
                hidden
                initialValue={'bundle'}
              ></Form.Item>
              <Col lg={24} md={24} sm={24} xs={24}>
                <Form.Item
                  name="context_id"
                  label="Select Bundle"
                  rules={[{ required: true }]}
                >
                  <BundleSelectionDropdown
                    onChange={(val: any) => {
                      form.setFieldValue('context_type', 'bundle');
                      form.setFieldValue('context_id', val);
                    }}
                    onClear={() => {
                      form.setFieldValue('context_type', null);
                      form.setFieldValue('context_id', '');
                    }}
                    selectedId={props.campaign?.contextId}
                  />
                </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={12} xs={12}>
                <Form.Item
                  name="thumbnail"
                  label="Thumbnail"
                  rules={[{ required: true }]}
                >
                  <Input.TextArea
                    ref={thumbField}
                    rows={4}
                    onChange={(e: any) => {
                      setThumbnail(e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={12} xs={12}>
                <Form.Item name="preview" label="Preview">
                  {thumbnail && (
                    <img
                      src={thumbnail}
                      alt={props.campaign?.title}
                      width="140px"
                    />
                  )}
                </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Form.Item name="start_at" label="Start">
                  <DatePicker
                    defaultValue={dayjs(props.campaign?.startAt)}
                    format={'YYYY-MM-DD'}
                  />
                </Form.Item>
              </Col>

              <Col lg={12} md={12} sm={24} xs={24}>
                <Form.Item name="end_at" label="End">
                  <DatePicker
                    defaultValue={dayjs(props.campaign?.endAt)}
                    format={'YYYY-MM-DD'}
                  />
                </Form.Item>
              </Col>
            </>
          )}
          <Col lg={24} md={24} sm={24} xs={24}>
            <Form.Item>
              <Button loading={loading} type="primary" htmlType="submit">
                {'Submit'}
              </Button>
              <Button
                style={{ marginLeft: '20px' }}
                htmlType="button"
                onClick={onReset}
              >
                Reset
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default CampaignForm;
