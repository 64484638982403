/* eslint-disable no-console */
import { Avatar, Col, Input, Row, Select } from 'antd';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { getBundles } from '../../api/bundles/request';
import { getUsers } from '../../api/users/request';
import { ChallengeStatus } from '../../enums/status';
import { ChallengeType } from '../../interfaces/challengeType';
import DropdownCard from '../dropdownCard/DropdownCard';
import styles from './Filter.module.scss';
import BundleSelectionDropdown from '../input/BundleSelectionDropdown';

interface Props {
  onFilter: (filterObj: any) => any;
  types: Array<ChallengeType> | null;
}

const INITIAL_FILTER_INPUT = {
  bundle_id: null,
  user_id: null,
  type: null,
  status: '',
};

const Filter = (props: Props) => {
  const { onFilter } = props;
  const types = props.types || [];
  const [filterInput, setFilterInput] = useState<any>(INITIAL_FILTER_INPUT);
  const [users, setUsers] = useState([]);
  let timeout: any = null;

  const fetchChallengers = async (q: any) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }

    timeout = setTimeout(async () => {
      const response = await getUsers({ q });

      console.log('users -->', response);
      if (q && response.data && response.data.length > 0) {
        console.log('users state -->', response.data);

        setUsers(response.data);
      }
    }, 500);
  };

  const onFilterHandler = (payload: any) => {
    setFilterInput({ ...filterInput, ...payload.value });
    onFilter({ ...filterInput, ...payload.value, page: 1 });
  };

  return (
    <div className={styles.Wrapper}>
      <Row gutter={30}>
        <Col flex="auto" style={{ minWidth: '350px' }}>
          <BundleSelectionDropdown
            onClear={() =>
              onFilterHandler({ type: 'bundle_id', value: { bundle_id: '' } })
            }
            onChange={(bundleId: number) =>
              onFilterHandler({
                type: 'bundle_id',
                value: { bundle_id: bundleId },
              })
            }
          />
        </Col>
        <Col flex="auto" style={{ minWidth: '200px' }}>
          <Input
            onChange={debounce((ev) => {
              onFilterHandler({ value: { q: ev.target.value } });
            }, 500)}
            allowClear={true}
            placeholder={'Search by keywords'}
          ></Input>
        </Col>
        {/* <Col flex="auto">
          <Select
            allowClear
            onChange={(item: any) =>
              onFilterHandler({ type: 'type', value: { type: item } })
            }
            placeholder="All types"
            style={{ width: '100%' }}
          >
            {types.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col flex="auto">
          <Select
            allowClear
            onChange={(status: any) =>
              onFilterHandler({ type: 'status', value: { status } })
            }
            placeholder="All statuses"
            style={{ width: '100%' }}
          >
            <Select.Option key={0} value={ChallengeStatus.Pending}>
              Pending
            </Select.Option>
            <Select.Option key={1} value={ChallengeStatus.Resolved}>
              Accepted
            </Select.Option>
            <Select.Option key={2} value={ChallengeStatus.Rejected}>
              Rejected
            </Select.Option>
            <Select.Option key={3} value={ChallengeStatus.Canceled}>
              Canceled
            </Select.Option>
          </Select>
        </Col> */}
      </Row>
    </div>
  );
};

export default Filter;
