import { Point } from '../../interfaces/point';

export const mapItemFromServerToClient = (item: any): Point => ({
  id: item.id,
  event: item.event,
  amount: item.amount,
  trnType: item.trn_type,
  particulars: item.particulars,
  createdAt: item.createdAt,
});

export const mapsFromServerToClient = (items: any[]): any[] =>
  items.map(mapItemFromServerToClient);
