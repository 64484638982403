import * as endpoints from '../../configs/endpoints';
import { MetaInterface } from '../../interfaces/api';
import { axioService, GET, POST } from '../../services/axioService';
import { getProviderId } from '../../services/utility';
import { mapMeta } from '../util';
import {
  mapUserBundles,
  mapUserFromServerToClient,
  mapUserShortFromServerToClient,
  mapUsersShortFromServerToClient,
} from './mapper';

interface ResponseInterface {
  data: Array<any> | any | null | any;
  meta?: MetaInterface | null;
}

/**
 * Fetch all users for the current provider with pagination
 * @param request - The request object
 * @returns {Promise<ResponseInterface>}
 */
export const getUsers = async (request?: any): Promise<ResponseInterface> => {
  const response = await axioService(
    GET,
    endpoints.SERVER_USERS_ENDPOINT,
    request,
    true
  );
  return {
    meta: mapMeta(response.data.meta),
    data: mapUsersShortFromServerToClient(response.data.data),
  };
};

/**
 * Fetch a user by id
 * @param request - The request object
 * @returns {Promise<ResponseInterface>}
 */
export const getUser = async (
  request: any
): Promise<{
  data: any;
}> => {
  const response = await axioService(
    GET,
    endpoints.SERVER_USER_SHOW_ENDPOINT.replace(':userId', request.id),
    request,
    true
  );
  return {
    data: mapUserShortFromServerToClient(response.data.data),
  };
};

/**
 * Fetch a user by id
 * @param request - The request object
 * @returns {Promise<ResponseInterface>}
 */
export const getUserBundles = async (
  request: any
): Promise<ResponseInterface> => {
  const response = await axioService(
    GET,
    `${endpoints.SERVER_USER_SHOW_ENDPOINT.replace(
      ':userId',
      request.id
    )}/bundles`,
    request,
    true
  );
  return {
    data: mapUserBundles(response.data.data),
    meta: mapMeta(response.data.meta),
  };
};

/**
 * create a new user
 * @param request the request body
 * @returns {Promise<UserDetailsInterface>}
 */
export const sendCampaignToUsers = async (
  request: any
): Promise<{
  data: any;
}> => {
  const response = await axioService(
    POST,
    endpoints.SERVER_USER_SEND_CAMPAIGN_ENDPOINT,
    request,
    true
  );
  return {
    data: response.data.data,
  };
};

/**
 * create a new user
 * @param request the request body
 * @returns {Promise<UserDetailsInterface>}
 */
export const createUser = async (
  request: any
): Promise<{
  data: any;
}> => {
  const response = await axioService(
    POST,
    endpoints.SERVER_USERS_STORE_ENDPOINT.replace(
      ':providerId',
      getProviderId()
    ),
    request,
    true
  );
  return {
    data: mapUserFromServerToClient(response.data.data),
  };
};

/**
 * create a new doctor
 * @param request the request body
 * @returns {Promise<UserDetailsInterface>} the updated doctor
 */
export const updateUser = async (
  request: any
): Promise<{
  data: any;
}> => {
  const response = await axioService(
    POST,
    endpoints.SERVER_USER_UPDATE_ENDPOINT.replace(
      ':providerId',
      getProviderId()
    ).replace(':userId', request.id),
    request,
    true
  );
  return {
    data: mapUserFromServerToClient(response.data),
  };
};

export const getRoles = async (request?: any): Promise<ResponseInterface> => {
  // const response = await axioService(
  //   GET,
  //   endpoints.SERVER_USERS_ENDPOINT.replace(':providerId', getProviderId()),
  //   request,
  //   true
  // );
  return {
    meta: null,
    data: [
      {
        label: 'Admin',
        value: 1,
      },
      {
        label: 'Doctor',
        value: 2,
      },
      {
        label: 'Patient',
        value: 3,
      },
      {
        label: 'Operator',
        value: 4,
      },
    ],
  };
};
