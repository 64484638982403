import { Quiz, QuizResponse } from '../../interfaces/quiz';
import { StudyLog } from '../../interfaces/studyLog';
import { mapFlashcardItemFromServerToClient } from '../flashcards/mapper';
import { mapUserFromServerToClient } from '../users/mapper';

export const mapFromServerToClient = (item: any): StudyLog => {
  return mapShortFromServerToClient(item);
};

export const mapShortFromServerToClient = (item: any): StudyLog => ({
  id: item.id,
  time: item.studied_at,
  isRight: item.is_right,
  type: item.type,
  user: mapUserFromServerToClient(item.user),
  flashcard: mapFlashcardItemFromServerToClient(item.flashcard),
  ...item,
});

export const mapsFromServerToClient = (items: any[]): any[] =>
  items.map(mapFromServerToClient);

export const mapsShortFromServerToClient = (items: any[]): any[] =>
  items.map(mapShortFromServerToClient);
