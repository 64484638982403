import { Challenge } from '../../interfaces/challenge';
import moment from 'moment';
import { ChallengeStatus } from '../../enums/status';

const getStatusLabel = (status: number) => {
  switch (status) {
    case ChallengeStatus.Pending:
      return 'Pending';
    case ChallengeStatus.Resolved:
      return 'Accepted';
    case ChallengeStatus.Rejected:
      return 'Rejected';
    case ChallengeStatus.Canceled:
      return 'Canceled';

    default:
      break;
  }
};

const getStatusColor = (status: number) => {
  switch (status) {
    case ChallengeStatus.Pending:
      return 'orange';
    case ChallengeStatus.Resolved:
      return 'green';
    case ChallengeStatus.Rejected:
      return 'red';
    case ChallengeStatus.Canceled:
      return '#ddd';
    default:
      break;
  }
};

const getStatusObj = (status: number) => {
  return {
    label: getStatusLabel(status),
    color: getStatusColor(status),
  };
};
export const mapChallengeItemFromServerToClient = (challenge: any): any => {
  const item = challenge.flashcard;
  return {
    id: challenge.id,
    originalAnswer: challenge.original_answer,
    expectedAnswer: challenge.your_answer,
    challengedAt: challenge.challenged_at
      ? moment(challenge.challenged_at)
      : null,
    status: challenge.status,
    statusObj: getStatusObj(challenge.status),
    statusLabel: challenge.status_message,
    typeLabel: challenge.type_label,
    approvedBy: challenge.approved_by,
    remarks: challenge.remarks,
    flashcard: {
      id: item.id,
      bundle_id: item.bundle_id,
      bundle_title: item.bundle_title,
      title: item.title,
      options: item.options,
      answer: item.answer,
      notes: {
        content: item.notes?.content || '',
        media: item.notes?.media || [],
      },
      media: item.media,
    },
    user: challenge.challenged_by
      ? {
          id: challenge.challenged_by.id,
          name: challenge.challenged_by.name,
          username: challenge.challenged_by.username,
          email: challenge.challenged_by.email,
          mobile: challenge.challenged_by.mobile,
        }
      : null,
  };
};

export const mapChallengesServerToClient = (
  appointments: any[]
): Challenge[] => {
  return appointments.map(mapChallengeItemFromServerToClient);
};
