import { MetaInterface } from '../interfaces/api';

export const mapMeta = (meta: any): MetaInterface => {
  return {
    currentPage: meta.current_page,
    page: meta.page,
    perPage: meta.per_page,
    total: meta.total,
  };
};

export interface ResponseInterface<T> {
  data: Array<T> | any | null;
  meta?: MetaInterface | null;
}

export interface SingleResponseInterface<T> {
  data: T | any | null;
}
