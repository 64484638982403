/* eslint-disable no-console */
/* eslint-disable jsx-a11y/anchor-is-valid */
import type { ActionType, ProColumns } from '@ant-design/pro-components';
import { ProTable } from '@ant-design/pro-components';
import { Button, Modal, Pagination, Space, Tooltip } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { getFlashcards } from '../../api/flashcards/request';
import Filter from './Filter';
import './FlashcardList.scss';
import { Flashcard } from '../../interfaces/flashcard';
import EditOption from '../FlashcardForm/EditOption';
import { LayoutContext } from '../layout/LayoutContext';
import { PlusOutlined } from '@ant-design/icons';

const FlashcardList = () => {
  const actionRef = useRef<ActionType>();
  const [flashcards, setFlashcards] = useState<Array<Flashcard>>([]);
  const [meta, setMeta] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  const layoutContext = useContext(LayoutContext);
  const setPageMenus = layoutContext?.setPageMenus;
  const [filterParams, setFilterParams] = useState({
    page: 1,
    per_page: 30,
    q: '',
    bundle_id: '',
  });
  const CreateCard = () => (
    <Button onClick={() => alert('This feature yet to implement.')}>
      <PlusOutlined /> Flashcard
    </Button>
  );
  useEffect(() => {
    fetchFlashcards(filterParams);
    setPageMenus([
      {
        component: CreateCard,
      },
    ]);
  }, [filterParams.page, filterParams.q, filterParams.bundle_id]);

  const fetchFlashcards = async (requestParam: any) => {
    setLoading(true);
    try {
      const response = await getFlashcards(requestParam);
      setFlashcards(response.data);
      setMeta(response.meta);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  const updateFlashcard = (flashcard: any) => {
    console.log('updated cards-->', flashcard);
    const items = flashcards.map((item: any) => {
      if (item.id === flashcard.id) {
        return { ...item, ...flashcard };
      }
      return item;
    });
    setFlashcards(items);
  };

  const columns: ProColumns<any>[] = [
    {
      title: '#SL',
      render: (text, record, index) =>
        `${
          meta.current_page * meta.per_page - meta.per_page + index + 1
        }`.padStart(2, '0'),
      onCell: () => {
        return {
          style: { textAlign: 'center' },
        };
      },
    },
    {
      title: 'Title',
      dataIndex: 'title',
      sorter: (a, b) => `${a.title}`.localeCompare(b.title),
      render: (text, record, index) => {
        const title: string = record.title;
        return (
          <div title={title} style={{ width: '250px' }}>
            {title}
            <div className="TextMeta">
              <small>{record.bundle_title}</small>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Options',
      dataIndex: 'options',
      render: (text, record: Flashcard, index) => {
        return <>{record.options.join(', ')}</>;
      },
    },
    {
      title: 'Answer',
      dataIndex: 'answer',
      render: (text, record: Flashcard, index) => {
        return <>{record.options[record.answer - 1] || ''}</>;
      },
    },
    {
      title: 'Actions',
      valueType: 'option',
      hideInSetting: true,
      key: 'actions',
      render: (text, record, index) => (
        <EditOption flashcard={record} onUpdate={updateFlashcard} />
      ),
    },
  ];

  const onPaginateChange = (value: number) => {
    const params = { ...filterParams, page: value };
    setFilterParams(params);
  };
  const onFilter = (filter: any) => {
    setFilterParams({ ...filterParams, ...filter });
  };

  return (
    <div>
      <Filter onFilter={onFilter} types={meta?.types} />
      <ProTable<any>
        dataSource={flashcards}
        columns={columns}
        actionRef={actionRef}
        search={false}
        pagination={false}
        rowKey="id"
        cardBordered
        loading={loading}
        size="small"
        className="flashcard-table"
        options={{
          density: false,
          fullScreen: false,
          reload: false,
          setting: false,
        }}
      />
      <div className="list-pagination">
        <Pagination
          onChange={onPaginateChange}
          defaultCurrent={1}
          total={meta?.total || 0}
          pageSize={meta?.per_page || 15}
          showSizeChanger={false}
        />
      </div>
    </div>
  );
};

export default FlashcardList;
