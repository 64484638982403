import React, { useEffect, useState } from 'react';
import { Button, Modal, Space } from 'antd';
import FlashcardFormContent from './FlashcardFormContent';
import { Flashcard } from '../../interfaces/flashcard';
import { EditOutlined } from '@ant-design/icons';
interface Props {
  flashcard: Flashcard;
  onUpdate?: any;
}
const EditOption = (props: Props) => {
  const flashcard = props.flashcard;
  const onUpdate = props.onUpdate;

  const [isModalVisible, setIsModalVisible] = useState(false);

  const updateFlashcard = (item: any) => {
    onUpdate(item);
    setIsModalVisible(false);
  };
  return (
    <>
      <Space size="middle">
        <Button
          type="text"
          onClick={() => {
            setIsModalVisible(true);
          }}
        >
          <EditOutlined />
        </Button>
      </Space>
      <Modal
        title={flashcard?.title || 'Edit flashcard'}
        open={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        width="60%"
        footer={false}
      >
        <FlashcardFormContent
          flashcard={flashcard}
          shortForm={true}
          showTitle={false}
          onUpdate={updateFlashcard}
        />
      </Modal>
    </>
  );
};

export default EditOption;
