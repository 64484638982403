/* eslint-disable no-console */
import * as endpoints from '../../configs/endpoints';
import { axioService, GET, POST } from '../../services/axioService';
import { mapTopicsServerToClient } from './mapper';

/**
 * Get all patients
 * @param page the number of page
 * @returns {Promise<CreatePatientResponse[]>} a promise of array of patients with pagination
 */
export const getTopics = async (): Promise<any> => {
  const response = await axioService(
    GET,
    endpoints.SERVER_TOPICS_ENDPOINT,
    {},
    true
  );

  return mapTopicsServerToClient(response.data.data);
};

export const updateBundle = async (
  request: any
): Promise<{
  data: any;
}> => {
  const response = await axioService(
    POST,
    endpoints.SERVER_BUNDLE_UPDATE_ENDPOINT.replace(':id', request.id),
    request,
    true
  );
  return {
    data: response,
  };
};
