import React, { useEffect, useState } from 'react';
import { Button, Modal, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import PointsForm from './PointsForm';
interface Props {
  userId: number;
  onAdd?: any;
  title?: string;
}
const AddPoints = (props: Props) => {
  const onUpdate = props.onAdd;

  const [isModalVisible, setIsModalVisible] = useState(false);

  const updateFlashcard = (item: any) => {
    onUpdate(item);
    setIsModalVisible(false);
  };
  return (
    <>
      <Space size="middle">
        <Button
          key="button"
          icon={<PlusOutlined />}
          onClick={() => {
            setIsModalVisible(true);
          }}
          type="primary"
        >
          Endorse
        </Button>
      </Space>
      <Modal
        title={props?.title || 'Endorse Points'}
        open={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        width="60%"
        footer={false}
      >
        <PointsForm userId={props.userId} onAdd={updateFlashcard} />
      </Modal>
    </>
  );
};

export default AddPoints;
