import React, { useEffect, useState } from 'react';
import { Card, Col, Divider, Row, Spin, Timeline } from 'antd';
import CardItem from './CardItem';
import { dashboard } from '../../api/dashboard/request';
import moment from 'moment';
import { Column } from '@ant-design/plots';
import {
  FilterParams,
  DashboardData,
  DailyCount,
  TodayTotal,
  StudyLogCount,
} from './dashboard.d';
import BarChart from './BarChart';
const Dashboard = () => {
  const [filterParams, setFilterParams] = useState<FilterParams>({
    start_date: moment().subtract(7, 'day').format(),
    end_date: moment().format(),
  });

  const [dashboardData, setDashboardData] = useState<DashboardData | null>(
    null
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [todayData, setTodayData] = useState<TodayTotal>({});
  const [weeklyData, setWeeklyData] = useState<DashboardData | null>(null);

  const mapDateIntervalData = (dashboardData: DashboardData) => {
    const bundle: Array<DailyCount> = [];
    const quiz: Array<DailyCount> = [];
    const user: Array<DailyCount> = [];
    const studyLog: Array<StudyLogCount> = [];

    const startDate = moment(filterParams.start_date);
    const endDate = moment(filterParams.end_date);

    while (startDate.isSameOrBefore(endDate, 'day')) {
      bundle.push(resolveDailyCountByDate(dashboardData.bundle, startDate));
      quiz.push(resolveDailyCountByDate(dashboardData.quiz, startDate));
      user.push(resolveDailyCountByDate(dashboardData.user, startDate));
      studyLog.push(
        resolveDailyCountByDate(dashboardData.studyLog || [], startDate)
      );

      startDate.add(1, 'day');
    }

    return {
      bundle,
      quiz,
      user,
      studyLog,
    };
  };

  const resolveDailyCountByDate = (data: Array<any>, date: moment.Moment) => {
    const defaultData = {
      timeline: date.format('YYYY-MM-DD'),
      total: 0,
      user_count: 0,
    };
    const dateFilter = (item: DailyCount) =>
      moment(item.timeline).isSame(date, 'date');

    return { ...defaultData, ...data.find(dateFilter) };
  };

  const filterTodayData = (dashboardData: DashboardData): TodayTotal => {
    return {
      bundle: resolveDailyCountByDate(dashboardData.bundle, moment()),
      quiz: resolveDailyCountByDate(dashboardData.quiz, moment()),
      user: resolveDailyCountByDate(dashboardData.user, moment()),
      studyLog: resolveDailyCountByDate(dashboardData.studyLog || [], moment()),
    };
  };

  const getDashboard = async (filterParams: any) => {
    try {
      const response = await dashboard(filterParams);
      const intervalData = mapDateIntervalData({
        ...response,
        studyLog: response.study_log,
      });
      setTodayData(filterTodayData(intervalData));
      setWeeklyData(intervalData);
      setDashboardData({ ...response, studyLog: response.study_log });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getDashboard(filterParams);
    setIsLoading(false);
  }, [filterParams]);

  const getChartConfig = (data: any) => ({
    data,
    xField: 'timeline',
    yField: 'total',
    height: 200,
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      type: {
        alias: '类别',
      },
      sales: {
        alias: '销售额',
      },
    },
  });

  const getStackedData = (data: any, label: string) =>
    data.reduce(
      (acc: any, it: DailyCount) => [
        ...acc,
        { timeline: it.timeline, name: label, total: it.total },
        { timeline: it.timeline, name: 'User', total: it.user_count },
      ],
      []
    );
  const getStackedLogData = (data: any, label: string) =>
    data.reduce(
      (acc: any, it: StudyLogCount) => [
        ...acc,
        { timeline: it.timeline, name: label, total: it.total },
        { timeline: it.timeline, name: 'User', total: it.user_count },
        { timeline: it.timeline, name: 'Read', total: it.read_count },
        { timeline: it.timeline, name: 'Revision', total: it.revision_count },
        { timeline: it.timeline, name: 'Quiz Ans', total: it.quiz_count },
      ],
      []
    );

  if (isLoading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '60px' }}>
        <Spin />
      </div>
    );
  }

  return (
    <>
      <div>
        <h4>
          <span className="TextMeta">Today</span>
        </h4>
        <Row gutter={10}>
          <Col lg={6} md={6} sm={12}>
            <CardItem
              dailyCount={todayData?.user}
              label={'User'}
              hint="Signup Users"
            />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <CardItem
              dailyCount={todayData?.bundle}
              label={'Bundle Purchase'}
              hint={`Unique Users: ${todayData?.bundle?.user_count}`}
            />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <CardItem
              dailyCount={todayData?.quiz}
              label={'Quiz'}
              hint={`Unique Users: ${todayData?.quiz?.user_count}`}
            />
          </Col>
          <Col lg={6} md={6} sm={12}>
            <CardItem
              dailyCount={todayData.studyLog}
              label={'Study Log'}
              hint={`
              Reading: ${todayData?.studyLog?.read_count},
              Revision: ${todayData?.studyLog?.revision_count},
              Quiz Ans: ${todayData?.studyLog?.quiz_count},
              Unique Users: ${todayData?.studyLog?.user_count} 
              `}
            />
          </Col>
        </Row>
      </div>
      <div style={{ marginTop: '20px' }}>
        <h4>
          <span className="TextMeta">Last week</span>
        </h4>
        <Row gutter={20}>
          <Col lg={12} md={12} sm={24}>
            <BarChart
              config={getChartConfig(weeklyData?.user || [])}
              title={'User Registration'}
            />
          </Col>
          <Col lg={12} md={12} sm={24}>
            <BarChart
              config={{
                ...getChartConfig(
                  getStackedData(weeklyData?.bundle || [], 'Bundle')
                ),
                isGroup: true,
                seriesField: 'name',
              }}
              title={'Bundle Purchase'}
            />
          </Col>
        </Row>

        <Row gutter={20} style={{ marginTop: '20px' }}>
          <Col lg={12} md={12} sm={24}>
            <BarChart
              config={{
                ...getChartConfig(
                  getStackedData(weeklyData?.quiz || [], 'Quiz')
                ),
                isGroup: true,
                seriesField: 'name',
              }}
              title={'Quiz'}
            />
          </Col>
          <Col lg={12} md={12} sm={24}>
            <BarChart
              config={{
                ...getChartConfig(
                  getStackedLogData(weeklyData?.studyLog || [], 'total')
                ),
                isGroup: true,
                seriesField: 'name',
              }}
              title={'Study Log'}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Dashboard;
