import { Flashcard } from '../../interfaces/flashcard';

export const mapFlashcardItemFromServerToClient = (item: any): Flashcard => {
  return {
    ...item,
    title: item.title,
    options: item.options,
    answer: item.answer,
    notes: {
      content: item.notes?.content || '',
      media: item.notes?.media || [],
    },
    media: item.media,
  };
};

export const mapFlashcardsServerToClient = (
  appointments: any[]
): Flashcard[] => {
  return appointments.map(mapFlashcardItemFromServerToClient);
};
