/* eslint-disable no-console */
import { Button, Col, Form, Input, Row, Select } from 'antd';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { resolveChallenge } from '../../api/challenges/request';
import { Bundle } from '../../interfaces/bundle';
import { Challenge } from '../../interfaces/challenge';

interface Props {
  challenge: Challenge;
  onApprove: any;
  submitBtnLabel: string;
  onCancel: any;
}

const ApprovalForm = (props: Props) => {
  const challenge = props.challenge;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const isEdit = !!props.challenge;

  useEffect(() => {
    onReset();
    form.setFieldsValue({
      ...challenge,
    });
  }, [props.challenge]);

  const onFinish = async (payload: any) => {
    resolveChallenge({ ...challenge, ...payload });
    props.onApprove({ ...challenge, ...payload });
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <div className={clsx('bg-white form__content')}>
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        form={form}
        name="control-hooks"
        onFinish={onFinish}
      >
        <Row gutter={15}>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              name="remarks"
              label="Remarks"
              rules={[{ required: challenge && challenge?.status !== 1 }]}
            >
              <Input.TextArea rows={5} />
            </Form.Item>
          </Col>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Form.Item>
              <Button
                loading={loading}
                type="primary"
                htmlType="submit"
                danger={challenge?.status !== 1}
              >
                {props.submitBtnLabel || 'Update'}
              </Button>
              <Button style={{ marginLeft: '20px' }} onClick={props.onCancel}>
                Cancel
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ApprovalForm;
